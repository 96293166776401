@mixin extendClass($newClass, $baseClass) {
  .#{$newClass} {
    @each $c in $baseClass {
      @extend .#{$c};
    }
  }
}

@mixin bootstrap-form-check-bgColor($pseudo, $disabled: false) {
  // default fallback
  & + label::#{$pseudo} {
    background-color: disable-color(
      $disabled,
      $custom-control-indicator-checked-bg
    ) !important;
  }

  @each $color, $value in map-merge($theme-colors, $energy-colors) {
    & + label {
      &.#{$color}::#{$pseudo} {
        background-color: disable-color($disabled, $value) !important;
      }
    }
  }
}

@mixin bootstrap-form-check-borderColor($disabled: false) {
  // default fallback
  & + label::before {
    border-color: disable-color(
      $disabled,
      $custom-control-indicator-checked-border-color
    ) !important;
  }

  @each $color, $value in map-merge($theme-colors, $energy-colors) {
    & + label {
      &.#{$color}::before {
        border-color: disable-color($disabled, $value) !important;
      }
    }
  }
}

@mixin bootstrap-form-check-focus {
  @each $color, $value in map-merge($theme-colors, $energy-colors) {
    & + label {
      &.#{$color}::before {
        $shadow-color: transparentize($value, 0.75);
        box-shadow: 0 0 0 $input-btn-focus-width $shadow-color !important;
      }
    }
  }
}

@mixin padding($padding) {
  padding: $padding;
}

@mixin footer-100 {
  @extend .position-absolute;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0;
}

@mixin carousel-indicators-color($direction, $color, $icon: "") {
  @if $direction == "prev" or $direction== "next" {
    @if $direction == "prev" {
      $icon: $carousel-control-prev-icon-bg;
    }
    @if $direction == "next" {
      $icon: $carousel-control-next-icon-bg;
    }
    background-image: str-replace(
      $icon,
      str-replace(#{$carousel-control-color}, "#", "%23"),
      $color
    );
  } @else {
    @error "direction must be either `prev` or `next`";
  }
}

@mixin carousel-hover($bgColor) {
  .carousel-indicators {
    li {
      background-color: $bgColor;
    }
    &:hover {
      background-color: $bgColor;
      li {
        background-color: $carousel-indicator-active-bg;
      }
    }
  }

  .carousel-control {
    &-prev-icon {
      @include carousel-indicators-color("prev", $bgColor);
    }

    &-next-icon {
      @include carousel-indicators-color("next", $bgColor);
    }

    &-prev:hover {
      .carousel-control-prev-icon {
        @include carousel-indicators-color("prev", "white");
      }
    }

    &-next:hover {
      .carousel-control-next-icon {
        @include carousel-indicators-color("next", "white");
      }
    }
  }
}

@mixin fade-it($fadeOpacity: 0.01, $fadeTransition: opacity 500ms ease-in) {
  &-enter {
    opacity: $fadeOpacity;

    &-active {
      opacity: 1;
      transition: $fadeTransition;
    }
  }

  &-leave {
    opacity: 1;

    &-active {
      opacity: $fadeOpacity;
      transition: $fadeTransition;
    }
  }
}

@mixin generate-font($name, $sizes) {
  @each $size in $sizes {
    .#{$name}-#{$size} {
      font-size: #{$size}px;
    }
  }
}

@mixin circled-image($bgColor, $size, $margin) {
  $innerSize: $size - 2 * $margin;

  @extend .#{$bgColor};
  @extend .mx-auto;
  @extend .p-0;
  border-radius: 50% !important;
  width: #{$size}rem !important;
  height: #{$size}rem !important;

  svg,
  img {
    width: #{$innerSize}rem !important;
    height: #{$innerSize}rem !important;
    margin-top: #{$margin}rem !important;
  }
}

// Overrides the background/foreground/border color
// Both the $bgColor, $fgColor and $borderColor must be a valid color code/name, or false to ignore the respective attribute color change
@mixin override-color($bgColor: false, $fgColor: false, $borderColor: false) {
  @if $bgColor {
    background-color: $bgColor !important;
  }

  @if $fgColor {
    color: $fgColor !important;
  }

  @if $borderColor {
    border-color: $borderColor !important;
  }
}

// Overrides the buttons color
// $variant is a valid Bootstrap variant: primary, secondary, success, danger, warning, info, light, dark, link
// Both the $bgColor and the $fgColor must be a valid color code/name, or false to ignore the respective attribute color change
@mixin override-button($variant, $bgColor, $fgColor: false) {
  .#{shared-var("BaseButtonBS") },
  .btn {
    &.btn-#{$variant} {
      @include override-color($bgColor, $fgColor, $bgColor);

      @include hover-focus {
        background-color: darken($bgColor, 10%) !important;
      }
    }
  }
}

// Overrides the page header menubar color
// Both the $bgColor, $fgColor and the $headerSeparator must be a valid color code/name, or false to ignore the respective attribute color change
@mixin override-header-menubar(
  $menu-bg,
  $menu-sm-bg,
  $menu-sm-fg,
  $menu-dropdown-bg,
  $menu-dropdown-fg,
  $header-separator: false
) {
  // mobile devices only
  @include media-breakpoint-down(sm) {
    .#{shared-var("MenuBarHeaderBS") } {
      nav.navbar {
        &.navbar-dark,
        .nav-link {
          @include override-color($menu-sm-bg, $menu-sm-fg);
        }
      }
    }
  }

  .#{shared-var("PageHeaderBS") } {
    .#{shared-var("MenuBarBS") } {
      // active group menu only
      .dropdown-menu,
      .dropdown-item {
        @include media-breakpoint-down(sm) {
          @include override-color($menu-sm-bg, $menu-sm-fg);
        }
        @include override-color($menu-dropdown-bg, $menu-dropdown-fg);
      }

      .navbar {
        .#{shared-var("MenuBarItemBS") } {
          // header menuitem and mobile dropdown menuitems only
          .nav-link {
            @include media-breakpoint-down(sm) {
              @include override-color($menu-sm-bg, $menu-sm-fg);
            }
            @include override-color($menu-bg, $menu-dropdown-fg);
          }

          @if $header-separator {
            @include media-breakpoint-up(sm) {
              &:not(:last-child) {
                border-right: 1px solid $header-separator !important;
              }
            }
          }
        }
      }
    }
  }
}

// Overrides the page footer color
// Both the $bgColor and the $fgColor must be a valid color code/name, or false to ignore the respective attribute color change
@mixin override-footer($bgColor, $fgColor: false) {
  footer.#{shared-var("PageFooterBS") } {
    @include override-color($bgColor, $fgColor);
  }
}

// Overrides the page footer brand row color
// Both the $bgColor and the $fgColor must be a valid color code/name, or false to ignore the respective attribute color change
@mixin override-footer-brand($bgColor, $fgColor: false) {
  footer.#{shared-var("PageFooterBS") } {
    .#{shared-var("FooterBrandBS")} {
      @include override-color($bgColor, $fgColor);
    }
  }
}

// Overrides the page footer brand row color
// Both the $bgColor and the $fgColor must be a valid color code/name, or false to ignore the respective attribute color change
// @mixin override-shopping-cart-guide($variant) {
//   #{shared-var("ShoppingCartAssistantBS") } {
//     .btn-#{$variant} {
//       @include override-color($bgColor, $fgColor);
//     }
//   }
// }

// credit: http://hackingui.com/front-end/a-pure-css-solution-for-multiline-text-truncation/
@mixin text-truncate-lines($number, $line-height: 1.5, $bg-color: inherit) {
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* use this value to count block height */
  line-height: #{$line-height}rem;
  /* max-height = line-height ($line-height) * lines max $number */
  max-height: #{$number * $line-height}rem;
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;
  /* place for '...' */
  margin-right: -1rem;
  padding-right: 1rem;

  &::before {
    /* points in the end */
    content: "...";
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of block */
    right: 0;
    bottom: 0;
  }

  &::after {
    /* points in the end */
    content: "";
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of text */
    right: 0;
    /* set width and height */
    width: 1rem;
    height: 1rem;
    margin-top: 0.2rem;
    /* bg color = bg color under block */
    background: $bg-color;
  }
}

// apply opacity on hover
@mixin hover-opacity {
  &:hover {
    opacity: 0.7;
    transition: opacity ease 0.25s;
  }
}

// apply a right-to-left scroll animation
@mixin scroll-left($speed: 1, $timing: linear, $duration: infinite) {
  animation: scroll-left calc(#{$speed * 10}s * var(--speed-factor, 1)) $timing
    $duration !important;

  // we don't want wrapped scrolling text, do we?
  @extend .text-nowrap;
}

@mixin scroll-top-in($speed: 1, $timing: linear) {
  transform: translateY(0%);
  animation: scroll-in #{$speed}s $timing !important;
}

@mixin scroll-top-out($speed: 1, $timing: linear) {
  transform: translateY(-100%);
  animation: scroll-out #{$speed}s $timing !important;
}

// mixin for decorating the homepage campaing buttons
@mixin campaign-button($id, $background: $danger, $border: $danger) {
  .#{shared-var("CampaignBS")}-button-#{$id} button {
    @include button-variant($background, $border);
  }
}

@mixin link-style($color) {
  // set link styling for all escaped route links except when element style is used
  a.#{shared-var("EscapedRouteLinkBS")}:not(.use-style) {
    color: $color !important;
  }
}

// mixin for customizing the product series item color
@mixin override-product-series(
  $product-series-summary-bg,
  $product-series-summary-fg,
  $product-series-button-bg
) {
  .#{shared-var("ProductSerieItemBS")} {
    &-summary-wrapper {
      background-color: $product-series-summary-bg;
      color: $product-series-summary-fg;

      button.btn {
        $hover-border: darken($product-series-button-bg, 10%);
        $hover-background: darken($product-series-button-bg, 7.5%);

        background-color: $product-series-button-bg !important;
        color: color-yiq($product-series-button-bg) !important;
        border-color: $product-series-button-bg !important;

        &:hover {
          color: color-yiq($hover-background) !important;
          background-color: $hover-background !important;
          border-color: $hover-border !important;
        }

        &:focus {
          color: color-yiq($hover-background) !important;
          background-color: $hover-background !important;
          border-color: $hover-border !important;
        }
      }
    }
  }
}

// mixin for customizing the product subcategory item color
@mixin override-subcategory($product-subcategory-bg, $product-subcategory-fg) {
  .#{shared-var("ProductCategoryHeaderBS")} {
    &-subcategories {
      a {
        background-color: $product-subcategory-bg !important;
        color: $product-subcategory-fg !important;

        &:hover {
          background-color: $product-subcategory-bg !important;
          color: $product-subcategory-fg !important;
        }
      }
    }
  }
}

// mixing for customizing the product item discount ribbon color
@mixin override-discount-ribbon($discount-ribbon-bg, $discount-ribbon-fg) {
  .#{shared-var("ProductCategoryItemBS")} {
    .thumbnail {
      .nav-link {
        .#{shared-var("DiscountBS")} {
          background-color: $discount-ribbon-bg !important;
          color: $discount-ribbon-fg !important;
        }
      }
    }
  }
}

@mixin override-card-deck-overlay(
  $background,
  $center-text: true,
  $has-hover-opacity: true,
  $breakpoints: ("": 6, "sm": 4, "md": 2),
  $has-overlay-opacity: true
) {
  .#{shared-var("CardDeckOverlayRowBS") } {
    @if $breakpoints {
      .row > div {
        @each $breakpoint, $value in $breakpoints {
          $key: ".col";
          @if $breakpoint != "" {
            $key: #{$key}-#{$breakpoint};
          }

          @extend #{$key}-#{$value};
        }
      }
    }

    .#{shared-var("BackgroundButtonCardDeckBS") } {
      @if $background {
        background-color: $background;
      }

      @if $has-hover-opacity {
        @include hover-opacity;
      }

      @if $center-text {
        &-overlay {
          @extend .d-flex;
          @extend .align-items-center;
          @extend .justify-content-center;
        }

        &-title {
          @extend .text-center;
          @extend .text-wrap;
        }
      }

      &-overlay {
        @if $has-overlay-opacity {
          background-color: rgba(0, 0, 0, 0.3) !important;
        } @else {
          background-color: rgba(0, 0, 0, 0) !important;
        }
      }
    }
  }
}

// define custom css properties base on a list/map
@mixin embed-custom-variables($list) {
  @each $name, $value in $list {
    --#{$name}: #{$value};
  }
}
