.#{shared-var("UserMenuBarBS")} {
  .card {
    &.active {
      .card-header {
        @extend .bg-secondary;
        @extend .text-light;
        @extend .font-weight-bold;
      }
    }
  }

  .row:not(.disabled) > div:first-child {
    @extend .text-muted;
  }

  // .row:not(.disabled):hover {
  //   background-color: $gray-300 !important;
  // }

  .row.active {
    @extend .font-weight-bold;
    &:not(.edit-favorites) {
      svg {
        @extend .text-primary;
      }
    }
    &.edit-favorites {
      svg {
        @extend .text-danger;
      }
    }
  }

  &.disabled,
  .disabled {
    color: $gray-500 !important;
  }
  &:not(.disabled) {
    @extend .cursor-pointer;
  }
}
