.#{shared-var("CampaignBS")} {
  &-header {
    font-size: 0.8rem;
    @extend .pt-4;
    @extend .text-center;
    &-title {
      @extend .font-weight-bolder;
    }
    &-text {
    }
  }

  &-right {
    @extend .h-100;
    &-image {
      @extend .px-0;
      height: 50%;
    }
  }

  &-buttons {
    @extend .my-3;

    button {
      @extend .h-100;
      @extend .text-uppercase;
      @extend .font-weight-bolder;
    }

    @include media-breakpoint-up(md) {
      height: 4rem;
      button {
        font-size: 1.4rem;
      }
    }
  }

  &-carousel {
    &.carousel-item {
      .loading {
        div:not([role="status"]) {
          @extend .w-100;

          @include media-breakpoint-up(sm) {
            height: 292px !important;
          }
          @include media-breakpoint-up(md) {
            height: 371px !important;
          }
          @include media-breakpoint-up(lg) {
            height: 645px !important;
          }
        }
      }

      img {
        @extend .w-100;
        @extend .h-100;
      }
    }
  }

  &-images {
    @extend .pt-4;
    .col {
      @extend.px-1;
    }
  }
}
