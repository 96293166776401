.#{shared-var("PageToolbarBS")} {
  @extend .d-flex;
  @extend .sticky-top;

  .btn-toolbar {
    @extend .m-1;
  }

  &.toolbar-left,
  &.toolbar-right {
    z-index: 1;
    @include media-breakpoint-down(sm) {
      position: fixed !important;
    }
    @extend .d-md-flex;
    &,
    .btn-toolbar-group {
      @extend .flex-column;
    }
  }

  &.toolbar-right {
    @extend .float-right;
    @include media-breakpoint-down(sm) {
      right: 0 !important;
    }
  }

  &.toolbar-left {
    @include media-breakpoint-down(sm) {
      left: 0 !important;
    }
  }

  &.toolbar-top {
    @include media-breakpoint-down(xs) {
      transform: translateY(0px);
    }
    @include media-breakpoint-up(sm) {
      transform: translateY(18px);
    }
    @include media-breakpoint-up(md) {
      transform: translateY(0rem);
    }
  }

  &.toolbar-top,
  &.toolbar-bottom {
    &,
    .btn-toolbar-group {
      @extend .flex-row;
    }
  }
}
