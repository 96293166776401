.#{shared-var("LogoListBS")} {
  @extend .mb-4;
  @extend .py-4;
  @extend .border-top;
  @extend .border-bottom;
  @extend .justify-content-around;
  @extend .align-items-center;

  &-item {
    @extend .my-2;
    @extend .h-100;
    @extend .d-flex;
    @extend .justify-content-center;
  }

  .loading {
    min-height: unset !important;
    div:not([role="status"]) {
      height: 70px !important;
    }
  }

  img {
    max-height: 4rem;
    object-fit: scale-down !important;
  }

  .carousel-inner {
    @extend .px-3;
  }

  .carousel-control-prev,
  .carousel-control-next {
    @include media-breakpoint-up(lg) {
      div.rounded:hover {
        background-color: rgba(0, 0, 0, 0.2) !important;
      }
      color: unset !important;
    }

    div {
      background-color: transparent !important;
    }

    & > div {
      @extend .p-0;
      @include media-breakpoint-down(lg) {
        background-color: rgba(0, 0, 0, 0.2) !important;
      }
    }

    width: 2rem;

    background-color: rgba(0, 0, 0, 0.2);
  }
}
