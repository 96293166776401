.ribbon-wrapper {
  @extend .fixed-bottom;
  @extend .overflow-hidden;
  width: 85px;
  height: 88px;
  left: calc(100% - 83px);
}

.ribbon {
  @extend .font-weight-bold;
  @extend .text-center;
  @extend .position-relative;
  padding: 7px 0;
  width: 120px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.75);
}

.ribbon-wrapper-top {
  top: -3px;
  right: -3px;
}

.ribbon-top {
  transform: rotate(45deg);
  left: -0.5rem;
  top: 1rem;
}

.ribbon-wrapper-bottom {
  bottom: 0;
  right: -3px;
}

.ribbon-bottom {
  transform: rotate(-45deg);
  left: -0.5rem;
  top: 3rem;
}

.ribbon::before,
.ribbon::after {
  @extend .position-absolute;
  content: "";
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  bottom: -3px;
}

.ribbon::before {
  left: 0;
}

.ribbon::after {
  right: 0;
}
