.#{shared-var("LazyLoaderBS")} {
  @extend .position-relative;
  @extend .h-100;
  @extend .mx-auto;

  display: inherit;

  &.loading {
    z-index: 1;
  }

  .#{shared-var("SpinnerBS")} {
    @extend .position-absolute;
    top: 50%;
    left: 50%;
    margin-left: -1em; // half of spinner width
    margin-top: -1em; // half of spinner height
  }
}
