$total: ();

// set a height of the given class to boost little bit the page CLS
$min-heights: (
  ControlledTabsBS: (
    xs: 413,
    sm: 413,
    md: 100,
    lg: 100
  ),
  ProductRatingBS: (
    xs: 87
  ),
  price-wrapper: (
    xs: 218,
    sm: 274,
    md: 274,
    lg: 274
  ),
  image-gallery-wrapper: (
    xs: 117
  ),
  ImageCarouselBS: (
    xs: 200,
    sm: 200,
    md: 400,
    lg: 600
  ),
  // ProductSliderBS: (
  //   xs: 500,
  //   sm: 500,
  //   md: 589,
  //   lg: 638
  // )
);

// Set the given attribute value of the class given by key
@mixin set-attr($key, $attr, $add-total: true, $map: false) {
  $breakpoints: ();
  @if $map {
    $breakpoints: map-merge($breakpoints, $map);
  } @else {
    @if map-has-key($min-heights, $key) {
      $breakpoints: map-merge($breakpoints, map-get($min-heights, $key));
    }
  }

  @each $breakpoint, $value in $breakpoints {
    @include media-breakpoint-up($breakpoint) {
      #{$attr}: #{$value}px !important;

      @if $add-total {
        @if map-has-key($total, $breakpoint) {
          $total: map-merge(
            $total,
            (
              $breakpoint: $value + map-get($total, $breakpoint)
            )
          ) !global;
        } @else {
          $total: map-merge(
            $total,
            (
              $breakpoint: $value
            )
          ) !global;
        }
      }
    }
  }
}

// Set the min-height of the class given by key
@mixin set-min-height($key, $add-total: true, $map: false) {
  @include set-attr($key, min-height, $add-total, $map);
}

// Set the height of the class given by key
@mixin set-height($key, $add-total: true, $map: false) {
  @include set-attr($key, height, $add-total, $map);
}

.#{shared-var("ProductBS")} {
  @extend .mt-3;

  .#{shared-var("ControlledTabsBS")} {
    &-wrapper {
      @include set-min-height("ControlledTabsBS");

      a[role="tab"].nav-item {
        &.active {
          @extend .font-weight-bold;
        }
      }
      h2 {
        font-size: inherit;
        font-weight: inherit;
        @extend .mb-0;
      }
    }

    .product-docs {
        a {
          display: block;
        }
    }

  }

  .#{shared-var("ProductRatingBS")} {
    &-wrapper {
      @include set-min-height("ProductRatingBS");
    }
  }

  &-price-wrapper {
    @include set-min-height("price-wrapper");
  }

  .image-gallery-wrapper {
    @include set-min-height("image-gallery-wrapper");
  }

  &-#{shared-var("RatingWidgetBS")} {
    .btn-link {
      @extend .text-dark;
    }
  }

  .#{shared-var("ProductTitleBS")} {
    @extend .text-center;
  }

  .#{shared-var("FavoriteBS")} {
    @extend .position-absolute;
    right: 0;
    top: 0;
  }

  .#{shared-var("ProductPriceBS")} {
    @extend .py-3;

    &.row {
      @extend .bg-light;
      @extend .rounded;
      @extend .m-0;
      @extend .m-sm-3;
      @extend .p-3;
      & > .col {
        @extend .p-0;
        @extend .p-sm-3;
      }
    }

    &-actual {
      font-size: $h1-font-size;
    }
  }

  &.#{shared-var("PaymentMethodIconsBS")} {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-around;
    @extend .mr-lg-3;

    text-align: center !important;

    @include media-breakpoint-up(md) {
      justify-content: end !important;
    }
  }

  &.#{shared-var("ImageCarouselBS")} {
    .carousel-inner {
      @extend .mx-5;
      @extend .w-auto;
    }

    .carousel-control-prev,
    .carousel-control-next {
      //background-color: transparent !important;
    }

    &-item {
      .loading,
      img,
      video {
        @extend .w-100;
        @include set-height("ImageCarouselBS");
      }

      img {
        cursor: zoom-in;
      }
    }
  }

  .#{shared-var("ProductSliderBS")} {
    @include set-min-height("ProductSliderBS");
  }
}

.#{shared-var("ProductPageBS")} {
  @include set-min-height($key: null, $map: $total, $add-total: false);
}
