// the ribbon style applied over a product category item
.#{shared-var("ProductBS")} {
  .#{shared-var("RibbonFoldedBS")} {
    @include embed-custom-variables($folded-ribbon-defaults);

    @include ribbon-folded {
      // change its position by extending its position-left class
      // @extend .position-left;

      // override mixin's default variables below
      // example:
      // --fg-color: yellow;
      --bg-color: #000000b2;
      // --font-family: sans-serif;
      --font-size: 1.5rem;
      --max-width: 11rem;
      // --top: calc((100% - 6 * var(--font-size)));
      // --bottom: 2 * var(--font-size);
      --margin-left: 50%;
      --margin-right: 50%;
    }
  }

  .#{shared-var("RibbonCornerBS")} {
    @include embed-custom-variables($corner-ribbon-defaults);

    @include ribbon-corner {
      // change its position by extending its corner-top-right|corner-bottom-left|corner-bottom-right class
      // @extend .position-left-top;

      // override mixin's default variables below
      // example:
      // --fg-color: yellow;
      --bg-color: #000000b2;
      --font-family: sans-serif;
      --font-size: 1.5rem;
      --margin-left: 50%;
      --margin-right: 50%;
    }
  }

  .#{shared-var("RibbonAroundBS")} {
    @include embed-custom-variables($around-ribbon-defaults);

    @include ribbon-around {
      // override mixin's default variables below
      // example:
      // --fg-color: yellow;
      --top: 10px;
      --bg-color: #000000b2;
      --font-family: sans-serif;
      --font-size: 1.5rem;
      --margin-left: 40%;
      --margin-right: 40%;
      --margin-top: 3rem;
    }
  }

  .#{shared-var("RibbonBadgeBS")} {
    @include embed-custom-variables($badge-ribbon-defaults);

    @include ribbon-badge {
      // override mixin's default variables below
      // example:
      // --fg-color: yellow;
      // --top: 10px;
      // --bg-color: #000000b2;
      // --font-family: sans-serif;
      // --font-size: 1.5rem;
      --margin-left: 50%;
      --margin-right: 50%;
    }
  }
}
