.#{shared-var("ProductCategoryHeaderBS")} {
  & > .row {
    @extend .w-100;

    // NOTE: this is overriden by ribbons as needed (see src/stylesheets/common/ribbons/mixins/_all.scss)
    img {
      @extend .w-100;
      height: 192px !important;

      @include media-breakpoint-up(sm) {
        height: 275px !important;
      }

      @include media-breakpoint-up(md) {
        height: 183px !important;
      }

      @include media-breakpoint-up(lg) {
        height: 306px !important;
      }

      object-fit: contain !important;
    }
  }

  &-subcategories {
    @extend .d-flex;
    @extend .justify-content-start;
    //@extend .font-weight-bold;
    //@extend .text-uppercase;
    @extend .py-4;
    @extend .flex-wrap;

    @extend .text-center;
    @extend .align-items-center;
    @extend .flex-column;

    @extend .text-md-left;
    @extend .align-items-md-baseline;
    @extend .flex-md-row;

    a {
      // @extend .bg-secondary;
      // @extend .text-white;
      @extend .py-1;
      @extend .px-2;
      @extend .m-2;
      @extend .rounded;

      &:hover {
        // @extend .bg-secondary;
        // @extend .text-white;

        @extend .text-decoration-underline;
      }
    }

    @if $product-category-header-bg != inherit {
      @extend .mb-2;
      background-color: $product-category-header-bg;

      @if $product-category-rounded {
        @extend .rounded;
      }
    }
  }
}
