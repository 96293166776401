$z-index: $zindex-fixed + 1;

.#{shared-var("OffCanvasSidebarBS")}-opacity {
  @extend .fixed-top;
  @extend .w-100;
  @extend .h-100;

  z-index: $z-index;

  // fallback
  background-color: #aaaaaa;
  background-color: rgba(0, 0, 0, 0.75);

  &.collapsed {
    width: 0 !important;
  }
}

.#{shared-var("OffCanvasSidebarBS")} {
  @extend .fixed-top;
  @extend .h-100;
  @extend .flex-grow-0;
  @extend .flex-shrink-0;

  @include media-breakpoint-up(md) {
    @include transition(width 0.25s);
  }

  width: 85%;

  $handler-width: 3rem;
  $header-height: 3.5rem;
  $footer-height: 3.5rem;

  z-index: $z-index;
  max-width: 480px !important;

  top: 0 !important;
  left: 0 !important;

  &.collapsed {
    width: 0 !important;

    .#{shared-var("OffCanvasSidebarBS")}-handler {
      @extend .d-flex;
    }
  }

  &-inner {
    @extend .w-100;
    @extend .h-100;

    min-height: 100%;
    overflow: auto;
  }

  &-body {
    @extend .d-flex;
    @extend .flex-column;
    @extend .flex-grow-1;
    @extend .flex-shrink-1;
    @extend .flex-wrap;
    @extend .justify-content-between;
    @extend .w-100;
    @extend .p-3;
    @extend .bg-light;
    @extend .h-auto;

    min-height: 100%;
  }

  &-header,
  &-footer {
    @extend .d-flex;
    @extend .w-100;
    @extend .px-3;
    @extend .py-2;
    @extend .bg-secondary;
    @extend .text-light;

    background-color: transparentize($secondary, 0.1) !important;

    overflow: hidden;
    white-space: nowrap;
    z-index: $z-index + 1;
  }

  &-header {
    @extend .font-weight-bold;

    top: 0;
    min-height: $header-height;
  }

  &-footer {
    @extend .position-sticky;
    bottom: 0;
    min-height: $footer-height;
    align-items: center;
  }

  &-handler,
  .close {
    @extend .cursor-pointer;
  }

  &-handler {
    @extend .justify-content-center;
    @extend .align-items-center;
    @extend .position-fixed;
    @extend .d-none;
    @extend .text-nowrap;

    top: calc(50% - #{$handler-width});

    background-color: #cccccc;
    background-color: rgba(0, 0, 0, 0.3);

    border-top-right-radius: $handler-width !important;
    border-bottom-right-radius: $handler-width !important;

    &-icon {
      @extend .text-center;
      @extend .h-100;
      height: 5rem !important;

      svg {
        @extend .h-100;
      }

      width: $handler-width;
    }

    &-text {
      transform: rotate(-90deg);
    }
  }
}
