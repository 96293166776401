@mixin init-common-style($cover: false) {
  // common style
  @extend .position-absolute;

  @if $cover == true {
    // make sure the adjacent image sibling cover the whole space
    & + picture > img {
      object-fit: cover !important;
      // enforce the image to consume the whole space (img-fluid doesn't do exactly that)
      @extend .w-100;
      @extend .h-100;
    }
  }

  background: var(--bg-color);
  color: var(--fg-color);
  font-family: var(--font-family);
  font-size: var(--font-size);
  z-index: var(--z-index); // see common/ribbons/_all.scss
}

@import "./around";
@import "./badge";
@import "./corner";
@import "./folded";
