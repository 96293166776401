.#{shared-var("ProductPackageBS")} {
  @extend .text-center;

  & > .row > div {
    @extend .mb-2;
    @extend .col-lg-3;
    @extend .col-sm-6;
    @extend .col-12;
  }

  .item {
    @extend .h-100;

    @extend .border;
    @extend .rounded;

    .brand-title {
      @extend .text-muted;
      @extend .text-uppercase;
      @extend .font-weight-bolder;
      @extend .py-1;
      font-size: initial;
    }

    .thumbnail {
      img {
        object-fit: contain !important;
      }
    }
  }

  .#{shared-var("ProductTitleBS")}-title {
    font-size: initial;
  }
}
