.#{shared-var("PageTemplateBS") }-#{shared-var("CookiePolicyPageBS") } {
  h1,
  h2,
  h3 {
    @extend .font-weight-bold;
    font-size: inherit;
  }

  h1 {
    font-size: $h3-font-size;
    @extend .mb-3;
  }

  h2 {
    font-size: $h5-font-size;
  }

  .#{shared-var("TitledParagraphBS")} {
    &.container {
      &:not(.table-title) {
        @extend .my-3;
      }

      &.table-title {
        @extend .mt-3;
        @extend .mb-0;
      }
    }
  }

  .table-responsive {
    @extend .mx-4;
  }
}
