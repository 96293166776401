@import "./Page/all";

.#{shared-var("PageWrapperBS")} {
  &.toolbar-right {
    @extend .flex-row-reverse;
  }
  &.toolbar-bottom {
    @extend .flex-column-reverse;
  }
  &.toolbar-top {
    @extend .flex-column;
  }

  &.toolbar-left,
  &.toolbar-right {
    .#{shared-var("PageEditorBS")} {
      @extend .flex-grow-1;
    }
  }

  @mixin pseudo-overlay($pseudo, $color: $gray-300, $hover-color: $gray-400) {
    &::#{$pseudo} {
      position: absolute !important;
      font-size: $small-font-size;
      color: $color;
      content: attr(data-id);
    }

    &:hover:#{$pseudo} {
      color: $hover-color;
    }
  }

  .#{shared-var("PageEditorBS")}:not([role="navigation"]),
  .#{shared-var("PageTemplateEditorBS")},
  .#{shared-var("PageComponentEditorBS")} {
    @include pseudo-overlay("before");
  }

  .#{shared-var("PageTemplateItemEditorBS")} {
    @include pseudo-overlay("after");

    &.dragging::after {
      color: $gray-600;
    }
  }

  .#{shared-var("PageComponentEditorBS")} {
    &:hover:before {
      color: $gray-500;
    }
    .component-wrapper {
      .group-wrapper {
        @extend .mx-0;

        .colwidth-wrapper {
          @extend .border;
          @extend .rounded;
          background-color: $gray-200;
        }
      }
    }
  }

  .add-first-template-item {
    @extend .cursor-pointer;
    @extend .position-absolute;
    @extend .text-light;
    @extend .rounded-circle;
    @extend .p-1;

    top: calc(50% - 0.75em);

    background-color: $gray-200;

    &:hover {
      @extend .bg-primary;
      box-shadow: 0 0 4px 4px #ddd;
    }
  }

  @keyframes blink {
    0% {
      border: dotted;
    }
    50% {
      border-style: solid;
    }
  }

  .flashing-border {
    animation-name: blink;
    animation-duration: 0.5s;
    animation-timing-function: step-end;
    animation-iteration-count: 4;
    animation-direction: alternate;
  }

  .html-prettier {
    &.badge {
      &.disabled {
        @extend .d-none;
      }

      position: absolute;
      @extend .cursor-pointer;
      @extend .btn-outline-secondary;
      @extend .border;

      background-color: #dddddd90;
      margin-right: 0.5rem;
      z-index: 1;
    }
  }
}

.#{shared-var("PageWrapperBS")}-#{shared-var("PageEditorBS")} {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.#{shared-var("PageEditorBS")}:not([role="navigation"]) {
  @extend .py-3;
  @extend .rounded;
  @extend .d-flex;
  @extend .flex-column;
  @extend .align-items-center;

  &::before {
    top: 0.1rem;
    left: 3.5rem;
  }

  border: 2px outset $border-color;

  &:hover {
    @extend .bg-light;
    border-color: darken($border-color, 10%);
    box-shadow: 0 0 4px 4px #ddd;
  }

  .preview-wrapper {
    @include link-style($link-color);
  }

  @include media-breakpoint-down(sm) {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}

.available-component {
  @extend .cursor-pointer;

  .card-header,
  .card-footer {
    @extend .text-muted;
  }

  .card-body:not(.float-top) {
    @extend .d-flex;
  }

  &.active {
    .card-header,
    .card-footer {
      @extend .bg-primary;
      @extend .text-light;
    }
  }

  &:hover {
    @extend .border-primary;

    .card-header,
    .card-footer {
      @extend .bg-primary;
      @extend .text-light;
    }
  }

  .card-body > .container,
  .card-body > .container > .row > .col {
    @extend .px-1;
  }

  .thumbnail {
    @extend .border;
    @extend .rounded;
    @extend .p-1;

    .col-3,
    .col-9 {
      @extend .px-1;
    }

    .w-33 {
      width: 33.33%;
    }

    font-size: 50%;
  }
}
