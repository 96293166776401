.#{shared-var("AdyenCardBS")} {
  // see bootstrap/utilities/_embed.scss
  @mixin embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;

    &::before {
      display: block;
      content: "";
    }
  }

  @mixin embed-responsive-item {
    iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  @mixin embed-responsive-xy($x, $y) {
    &::before {
      padding-top: percentage($y / $x);
    }
  }

  @include media-breakpoint-down(sm) {
    .adyen-checkout__threeds2__challenge {
      @include embed-responsive();
      @include embed-responsive-xy(2, 1);

      iframe {
        @include embed-responsive-item();
      }
    }
  }
}
