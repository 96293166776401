.#{shared-var("PageToolbarButtonBS")} {
  &.popover {
    .thumbnail {
      @extend .border;
      @extend .rounded;

      .col-3,
      .col-9 {
        @extend .px-1;
      }

      .w-33 {
        width: 33.33%;
      }

      font-size: 50%;
    }
  }
}
