// credit: Ana Tudor
// https://css-tricks.com/recreating-the-twitter-heart-animation/

div.#{shared-var("FavoriteBS")} {
  $heart-color-on: #e2264d;
  $heart-color-off: $light;
  $shadow-color: #4a4a4a;
  $shadow-1: 0 0 3px $shadow-color;
  $shadow-2: 0 1px 1px $shadow-color;
  $shadow-3: 0;
  $shadow-4: -1px 1px $shadow-color;
  $shadow-5: 1px 0 1px $shadow-color;
  $shadow-6: -1px 0 1px $shadow-color;

  @extend .d-flex;
  @extend .h-100;
  @extend .justify-content-end;
  @extend .m-0;

  input[type="checkbox"] {
    @extend .position-absolute;
    left: -100vw !important;

    &:checked + label {
      color: $heart-color-on;
      will-change: font-size;
      animation: heart-on 0.35s ease-in-out !important;
    }
  }

  label[for] {
    @extend .align-self-center;
    @extend .cursor-pointer;
    color: $heart-color-off;
    font-size: 2em;
    user-select: none;
    z-index: 2;
  }

  input[type="checkbox"] + label {
    text-shadow: $shadow-1, $shadow-2, $shadow-3 $shadow-4, $shadow-5, $shadow-6;
  }

  @keyframes heart {
    0%,
    15% {
      font-size: 0;
    }
  }

  @keyframes heart-on {
    from {
      color: $heart-color-off;
    }
    to {
      color: $heart-color-on;
    }
  }

  @keyframes heart-off {
    from {
      color: $heart-color-on;
    }
    to {
      color: $heart-color-off;
    }
  }
}
