.#{shared-var("ShoppingCartBS")} {
  @extend .px-0;

  &-guide {
    &-thumbnail {
      img {
        @extend .d-block;
        @extend .mx-auto;

        // fallback for older browsers
        max-width: 100%;
        max-height: 100%;

        // modern browser support
        max-width: stretch;
        //max-height: stretch;

        object-fit: contain !important;
      }
    }
  }

  &-items,
  &-title {
    @extend .w-100;
  }

  &-items {
    @extend .mx-0;

    & > .col {
      @extend .px-0;
    }
  }

  &-subtotal {
    &-gross,
    &-vat-value {
      &.calculating {
        text-shadow: 0px 0px 3px;
      }
    }
  }
}
