##{shared-var("SearchEngineCustomBS")} {
  &-searchbox-only {
    input.form-control {
      @extend .border-0;
      background-color: #e4e3e1;

      // &::placeholder {
      //   @extend .text-muted;
      // }
    }

    .input-group-append {
      .input-group-text {
        background-color: $menu-bg !important;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }

    .dropdown {
      @extend .position-absolute;

      &-menu {
        &.show {
          top: 3em !important;
          opacity: 1 !important;
          pointer-events: auto !important;
        }
      }
    }
  }
}
