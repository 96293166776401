.#{shared-var("PageHeaderBS")} {
  @extend .mx-0;
  @extend .mx-auto;

  @include media-breakpoint-up(md) {
    @include sticky-top;
  }

  box-shadow: 0 -0.125rem 0.25rem rgba($black, 0.075) !important;
  max-width: $desktop-width !important;

  &.maximized {
    @extend .mw-100;
    @extend .w-100;
    @extend .mx-0;
  }

  &-wrapper {
    @extend .sticky-top;

    // notification-bar is part of page-header; when positioned fixed-bottom the footer(with a sticky z-index equal to
    // the page-header) would be shown on top of notification-bar; solution: give the page-header a higher z-index order
    z-index: $zindex-fixed !important;
  }

  .#{shared-var("MarqueeWidgetBS")} {
    @extend .d-flex;
    @extend .w-100;

    @include media-breakpoint-down(md) {
      height: 4.35rem;
    }
    @include media-breakpoint-up(md) {
      height: 3.15rem;
    }
    .loading {
      @extend .d-inline-block;
      min-height: auto !important;
    }
  }
}
