.#{shared-var("AdminDataGridBS")} {
  &.#{shared-var("GridBS")} {
    .#{shared-var("GridBS")} {
      // ---  header ---
      &-header {
        @extend .text-center;

        .#{shared-var("GridBS")}-col {
          @extend .px-2;

          &:first-child {
            max-width: 3rem !important;
            input {
              @extend .position-relative;
            }
          }

          &:nth-child(2) {
            max-width: 4rem !important;
          }
        }
      }

      // ---  rows ---
      &-row {
        .#{shared-var("GridBS")}-col {
          @extend .px-2;

          &:first-child {
            max-width: 3rem !important;
            input {
              @extend .position-relative;
            }
          }

          &:last-child {
            svg {
              @extend .ml-1;
            }
          }

          &:nth-child(2) {
            max-width: 4rem !important;
          }
        }
      }
    }
  }
}
