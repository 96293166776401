$badge-ribbon-defaults: (
  top: -0.1rem,
  right: -0.1rem,
  bottom: auto,
  left: auto,
  bg-color: #000000,
  bg-color-end: #000000,
  fg-color: #ffffff,
  font-family: inherit,
  font-size: inherit,
  width: 6rem,
  height: 4rem,
  padding-top: 1rem,
  margin-left: auto,
  margin-right: auto,
  margin-top: auto,
  margin-bottom: auto,
  max-width: "auto",
  // shadow: 1=yes, 0=no
  shadow: 1,
  rounded: 5px
);

// https://codepen.io/tay1orjones/pen/AajbXw
@mixin ribbon-badge {
  @content;

  @include init-common-style($cover: true);

  @extend .text-center;
  @extend .text-break;

  top: var(--top);
  bottom: var(--bottom);
  left: var(--left);
  right: var(--right);

  text-shadow: calc(1px * var(--shadow)) calc(1px * var(--shadow))
    calc(5px * var(--shadow)) rgba(0, 0, 0, 0.15);

  width: var(--width);
  height: var(--height);
  max-height: var(--max-height);
  min-height: var(--min-height);
  max-width: var(--max-width);
  min-width: var(--min-width);

  padding: 0;
  padding-top: var(--padding-top);

  filter: drop-shadow(
    0 calc(var(--shadow) * 0.5rem) calc(var(--shadow) * 0.3rem)
      rgba(0, 0, 0, 0.5)
  );

  background: linear-gradient(
    to bottom,
    var(--bg-color) 0%,
    var(--bg-color-end) 100%
  );

  border-top-left-radius: var(--rounded);
  border-top-right-radius: var(--rounded);

  &:after {
    @extend .position-absolute;
    content: "";
    width: 0;
    height: 0;
    left: 0;
    top: var(--height);
    border-right: calc(var(--width) / 2) solid transparent;
    border-left: calc(var(--width) / 2) solid transparent;
    //border-top: calc(var(--width) / 4) solid var(--bg-color-end);
    border-top: var(--pennant-size) solid var(--bg-color-end);
  }
}
