.#{shared-var("UserOrderDetailsBS")} {
  @extend .p-1;

  @media print {
    @include font-size(1.2em);
    margin: 2rem;
  }

  .supplier-info.row {
    @extend .mb-3;

    .logo.col {
      @extend .col-md-6;
      @extend .col-12;

      .brand img {
        max-height: 100px;
        object-fit: contain;
      }
    }
    .sales-order.col {
      @extend .col-md-6;
      @extend .col-12;

      .supplier-order {
        @extend .p-2;
        @extend .border;
        .col:first-child {
          @extend .font-weight-bold;
        }
      }
    }
  }

  .customer-info.row {
    @extend .mb-3;

    .customer-address {
      @extend .p-2;
      @extend .border;

      .row {
        &:first-child {
          @include font-size(0.75em);
        }
        &:nth-child(2) {
          @extend .font-weight-bold;
        }
      }
    }

    .delivery.col,
    .invoice.col {
      @extend .col-md-6;
      @extend .col-12;
      @extend .mb-3;
      @extend .mb-md-0;
    }
  }

  .order-items {
    .container {
      @extend .border;
    }

    &.row {
      @extend .text-left;

      .order-line.row {
        @extend .border-left;
        @extend .border-right;
        @extend .border-top-0;
        @extend .border-bottom-0;

        @media (max-width: 768px) {
          border-bottom: $border-width solid $border-color !important;
        }

        @extend .px-0;
        @extend .mx-0;

        &:first-child {
          @extend .border;
          @extend .text-center;
          @extend .font-weight-bold;
          .col {
            @extend .px-1;
            @extend .py-2;
          }
        }

        &:last-child {
          @extend .border-top;
          @extend .border-bottom;
          @extend .font-weight-bold;
        }

        .col:not(:last-child) {
          //@extend .border-right;
        }

        .product-id.col {
          @extend .col-2;
          @extend .col-md-1;
        }
        .description.col {
          @extend .col-10;
          @extend .col-md-7;
        }
        .qty.col {
          @extend .col-2;
          @extend .col-md-1;
          @extend .text-center;
        }
        .price-with-vat.col {
          @extend .col-3;
          @extend .col-md-1;
          @extend .text-right;
        }
        .vat-amount.col {
          @extend .col-3;
          @extend .col-md-1;
          @extend .text-right;
        }
        .gross-amount.col {
          @extend .col-4;
          @extend .col-md-1;
          @extend .text-right;
        }

        &.option {
          @extend .border-top;

          .option-type {
            @extend .font-weight-bold;

            &:after {
              content: ": ";
            }
          }

          & ~ .option {
            @extend .border-top-0;
            @extend .border-bottom-0;
          }

          .product-id.col {
            visibility: hidden;
          }
        }
      }
    }
  }
}

.modal-dialog {
  &.maximized {
    @include media-breakpoint-down(md) {
      max-width: 99vw !important;
      max-height: 99vw !important;
    }
    @include media-breakpoint-up(md) {
      max-width: 90vw !important;
    }

    @include media-breakpoint-up(xl) {
      max-width: 75vw !important;
    }
  }
}
