.#{shared-var("AdminServerStatusBS")} {
  .#{shared-var("AdminBezierChartBS")} {
    path {
      fill-opacity: 15%;

      $custom-color-map: (
        "primary": (
          stroke: $primary,
          fill: $primary
        ),
        "warning": (
          stroke: $warning,
          fill: $warning
        ),
        "danger": (
          stroke: $danger,
          fill: $danger
        )
      );

      @each $color, $colorMap in $custom-color-map {
        &.stroke-#{$color} {
          stroke: map-get($colorMap, "stroke") !important;
          fill: map-get($colorMap, "fill") !important;
        }
      }
    }
  }
}
