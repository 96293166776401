.#{shared-var("ImageFootnoteCardDeckBS")} {
  @extend .my-3;

  &-title {
    @extend .text-center;
    @extend .font-weight-bold;
  }

  &-items {
    .card-link {
      @extend .h-100;
    }

    .card {
      min-width: 150px;
      min-height: 150px;
    }

    .card-footer {
      background-color: $dark;
      color: $light;

      .#{shared-var("CardItemBS")}-title {
        @extend .font-weight-bold;
      }
    }
  }
}

.dropdown-menu {
  .#{shared-var("ImageFootnoteCardDeckBS")} {
    @extend .my-0;
    @extend .h-100;

    &-items {
      @extend .h-100;
    }

    .card-footer {
      @extend .h-50;
    }
  }
}
