.#{shared-var("UserPaymentListBS")} {
  .row {
    &:hover:not(:last-child):not(:first-child) {
      background-color: $gray-200 !important;
    }

    @extend .py-1;

    &:not(:last-child):not(:first-child) {
      &:nth-child(odd) {
        @extend .bg-light;
      }
      @include font-size(0.9rem);
    }

    &:not(:last-child) {
      @extend .border-bottom;
    }

    &:first-child,
    &:last-child:not(.no-data):not(.error):not(.loading) {
      @extend .font-weight-bolder;
      @extend .border-bottom;
      @extend .bg-secondary;
      @extend .text-light;
      @extend .py-2;
    }

    &.error {
      @extend .text-danger;
    }

    &.no-data,
    &.error,
    &.loading {
      @extend .py-2;
      @extend .text-center;
    }

    &:not(.no-data):not(.error):not(.loading) {
      &:last-child {
        .col {
          @extend .col-md-1;
          @extend .px-1;

          @extend .col-md-2;

          &:nth-child(5) {
            @extend .text-right;
          }

          &:nth-child(2),
          &:last-child {
            @extend .col-md-1;
          }
        }
      }

      &:not(:last-child) .col {
        @extend .text-break;
        @extend .px-1;
        @extend .text-center;

        &:nth-child(2),
        &:nth-child(3) {
          @extend .user-select-all;
        }

        &:first-child,
        &:last-child {
          @extend .col-md-1;
        }

        @extend .col-md-2;

        &:nth-child(5) {
          @extend .text-right;
        }

        &:last-child {
          svg {
            @mixin action-color($action, $color) {
              &.can-#{$action} {
                color: $color !important;
                &:hover {
                  color: lighten($color, 20%) !important;
                }
              }
            }

            @extend .cursor-pointer;

            $svg-color: $secondary;

            @include action-color("view", $info);
            @include action-color("print", $primary);
            @include action-color("remove", $danger);
          }
        }
      }
    }
  }
}
