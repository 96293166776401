$folded-ribbon-defaults: (
  fold-size: 10px,
  pennant-size: 15px,
  top: 30px,
  bottom: auto,
  left: auto,
  right: auto,
  bg-color: #000000,
  fg-color: #ffffff,
  rounded: 5px,
  font-family: inherit,
  font-size: inherit,
  max-width: 100%,
  min-width: auto,
  // shadow: 1=yes, 0=no
  shadow: 1,
  margin-left: auto,
  margin-right: auto,
  margin-top: auto,
  margin-bottom: auto
);

// https://www.freecodecamp.org/news/make-a-css-only-ribbon/
@mixin ribbon-folded {
  @content;

  // common style
  @include init-common-style($cover: true);

  max-width: var(--max-width);
  min-width: var(--min-width);

  top: var(--top);
  bottom: var(--bottom);

  // default style when no position given (default=right)
  left: var(--left);
  right: calc(-1 * var(--fold-size));

  padding-top: 0;

  padding-right: var(--fold-size);
  padding-bottom: var(--fold-size);
  padding-left: calc(var(--fold-size) + var(--pennant-size));

  box-shadow: 0 calc(-1 * var(--shadow) * var(--fold-size)) 0 inset #0005;

  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--fold-size)),
    calc(100% - var(--fold-size)) 100%,
    calc(100% - var(--fold-size)) calc(100% - var(--fold-size)),
    0 calc(100% - var(--fold-size)),
    var(--pennant-size) calc(50% - var(--fold-size) / 2)
  );

  border-top-right-radius: var(--rounded);

  margin-top: var(--margin-top);
  margin-right: var(--margin-right);
  margin-bottom: var(--margin-bottom);
  margin-left: var(--margin-left);

  &.tilt-down {
    clip-path: polygon(
      0 var(--fold-size),
      calc(100% - var(--fold-size)) var(--fold-size),
      calc(100% - var(--fold-size)) 0,
      100% var(--fold-size),
      100% 100%,
      0 100%,
      var(--pennant-size) calc(50% + var(--fold-size) / 2)
    );

    box-shadow: 0 calc(var(--shadow) * var(--fold-size)) 0 inset #0005;
    border-bottom-right-radius: var(--rounded);
    padding-top: var(--fold-size);
    padding-bottom: 0;
  }

  // default style for left positioning
  &.position-left {
    left: calc(-1 * var(--fold-size));
    right: var(--right);

    padding-right: calc(var(--fold-size) + var(--pennant-size));
    padding-bottom: var(--fold-size);
    padding-left: var(--fold-size);

    border-top-right-radius: 0;
    border-top-left-radius: var(--rounded);

    clip-path: polygon(
      100% 0,
      0 0,
      0 calc(100% - var(--fold-size)),
      var(--fold-size) 100%,
      var(--fold-size) calc(100% - var(--fold-size)),
      100% calc(100% - var(--fold-size)),
      calc(100% - var(--fold-size)) calc(50% - var(--fold-size) / 2)
    );

    &.tilt-down {
      clip-path: polygon(
        0 var(--fold-size),
        var(--fold-size) 0,
        var(--fold-size) var(--fold-size),
        100% var(--fold-size),
        calc(100% - var(--pennant-size)) calc(50% + var(--fold-size) / 2),
        100% 100%,
        0 100%
      );

      padding-bottom: 0;
      padding-top: var(--fold-size);
      border-top-left-radius: 0;
      border-bottom-left-radius: var(--rounded);
    }
  }
}
