.#{shared-var("CheckoutPlaceOrderSuccessBS")} {
  @extend .py-3;
  @extend .my-3;

  width: fit-content;

  @include media-breakpoint-down(md) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  @include media-breakpoint-up(md) {
    min-width: 80%;
  }

  &-header,
  &-footer,
  &-action {
    @extend .text-center;

    @include media-breakpoint-down(md) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  &-header {
    &-action {
      @extend .m-3;
      @extend .text-center;
    }

    h4 {
      @extend .h1;
    }

    h4,
    .email {
      @extend .font-weight-bold;
    }
  }

  &-order-payment {
    @extend .bg-light;
    //@extend .shadow-sm;
    @extend .rounded;
    @extend .m-3;
    @extend .p-3;

    @include media-breakpoint-down(md) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  &-order,
  &-payment {
    @extend .px-0;

    & > .container {
      @extend .px-0;
      .row {
        @extend .mx-0;
      }

      & .row:first-child {
        @extend .mt-4;
        h6 {
          @extend .font-weight-bold;
          //@extend .text-center;
        }
      }

      & .row:nth-child(n + 2) {
        span {
          &.label,
          &.value {
            @extend .px-1;

            &:first-child {
              &::after {
                content: ": ";
              }
              //@extend .text-right;
            }
            &:last-child {
              @extend .font-weight-bold;
            }
          }
        }
      }
    }

    .badge {
      @extend .text-wrap;
    }
  }

  &-payment-assistance {
    @extend .m-3;
    a[href^="tel"] {
      @extend .text-nowrap;
      @extend .text-decoration-underline;
    }
  }

  &-footer {
    & > .container {
      @extend .my-3;
    }

    @extend .font-weight-bold;
    @extend .text-primary;

    &-action {
      //@extend .m-3;
      @extend .text-center;
    }
  }
}
