.#{shared-var("EnergyClassBS")} {
  @extend .float-right;
  height: 3rem;
  min-width: 3rem;

  &.title {
    @extend .text-nowrap;
    @extend .d-block;
    @extend .text-decoration-underline;
    font-size: 0.75rem;
  }

  &-link {
    @extend .p-0;
  }
}
