.#{shared-var("DropdownGroupMenuBS")} {
  &.level-0 {
    &:not(.footnote-carddeck-aware),
    &.is-dropdown {
      @extend .text-uppercase;
    }
  }

  &.footnote-carddeck-aware {
    .#{shared-var("DropdownGroupMenuItemBS")} {
      &:not(.child) {
        @extend .font-weight-bold;
        text-decoration: $link-hover-decoration;
      }
    }
  }

  a {
    &.disabled * {
      color: $dropdown-link-active-color !important;
      @include gradient-bg($dropdown-link-active-bg);

      .#{shared-var("LazyLoaderBS")} {
        .#{shared-var("SpinnerBS")} {
          background-color: $dropdown-link-active-color;
        }
      }
    }

    &.disabled:hover {
      @extend .text-decoration-none;
    }
  }
}
