// .#{shared-var("NotificationBarBS")} {
//   &-item {
//     &.active {
//       background-color: $menu-bg !important;
//     }
//   }
// }

.h2 {
  font-weight: $font-weight-bold;
}

body {
  background-image: none;
  background-color: $body-bg;

  // prevents body scrolling while scrolling the positioned element
  &.noscroll {
    overflow: hidden;
  }
}

nav.navbar.navbar-expand-md.navbar-dark.bg-dark {
  background-color: $menu-bg !important;
}

.#{shared-var("MenuBarBS")} {
  .nav-link {
    font-weight: 600 !important;
  }
}

@media (min-width: 576px) {
  .#{shared-var("PageHeaderBS")} {
    .#{shared-var("MenuBarBS")} {
      .navbar {
        .#{shared-var("MenuBarItemBS")} {
          padding-top: 5px;
          padding-bottom: 7px;
        }
      }
    }
  }
}

.mr-auto .navbar-nav {
  height: 45px;
  font-weight: normal;
}

@media (min-width: 576px) {
  .#{shared-var("MenuBarItemBS")} {
    &-dropdown {
      .dropdown-menu {
        padding: 20px;
      }
    }
  }
}

.#{shared-var("ProductAvailabilityBS")} {
  &-buttons {
    .button {
      text-transform: none;
    }
  }
}

.#{shared-var("CardDeckImageCenteredFootnoteBS")} {
  &-item {
    box-shadow: none;

    &-image {
      margin-top: 10px;
    }
  }
}

@media (min-width: 768px) {
  .justify-content-md-center,
  .#{shared-var("MarqueeWidgetBS")} {
    justify-content: left !important;
  }
}

@media (max-width: 767px) {
  nav.navbar.navbar-expand-md.navbar-dark.bg-dark {
    background-color: $menu-sm-bg !important;
    color: $menu-sm-fg !important;
  }
}

.#{shared-var("ProductCategoryItemBS")}-buttons {
  .#{shared-var("ProductButtonsBS")} {
    @extend .px-0;
    @extend .mx-1;

    .#{shared-var("BaseButtonBS")} {
      @extend .px-1;
      font-size: 0.85rem;
    }
  }
}

.#{shared-var("ProductAvailabilityBS")} {
  &-from {
    font-size: 0.8rem;
  }
}

// set link styling for all escaped route links except when element style is used
@include link-style($link-color);

// set the homepage campaign buttons color
@include campaign-button(0, $outlet-btn-color, $outlet-btn-color);
@include campaign-button(1, $buygift-btn-color, $buygift-btn-color);
