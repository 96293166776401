.#{shared-var("ShoppingCartAssistantBS")} {
  //z-index: $zindex-dropdown - 1 !important; // menu has higher priority
  z-index: $zindex-fixed + 1 !important;

  @extend .p-3;
  @extend .shadow;
  @extend .rounded;

  width: 400px;
  right: 3rem;

  @include media-breakpoint-up(xl) {
    right: calc((100vw - 1240px) / 2);
  }
  @include media-breakpoint-down(lg) {
    left: calc(100vw - 400px);
  }
  @include media-breakpoint-up(sm) {
    position: fixed !important;
  }

  @extend .sticky-top;

  @include media-breakpoint-down(xs) {
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
  }

  @include media-breakpoint-down(sm) {
    top: $navbar-height - 2px !important;
  }

  box-shadow: $box-shadow;

  background-color: $dropdown-bg;

  &-thumbnail {
    img {
      @extend .d-block;
      @extend .mx-auto;

      // fallback for older browsers
      max-width: 100%;
      max-height: 100%;

      // modern browser support
      max-width: stretch;
      max-height: stretch;
    }
  }
}
