.#{shared-var("FooterLinksBS")} {
  @extend .my-4;

  & * {
    @extend .border-0;
  }

  &-column {
    border-right: 1px solid $light !important;
  }

  &-item {
    @extend .m-1;
  }

  &-title {
    @extend .px-1;
    h2 {
      @extend .text-nowrap;
      @extend .font-weight-bold;
      font-size: inherit;
    }
  }

  .col-md-3 {
    width: auto !important;
    padding: 0.5em;
  }

  .list-group-item-action {
    color: $white;
  }
}
