.#{shared-var("PageFooterBS")} {
  z-index: $zindex-sticky !important;

  @include footer-100;

  .#{shared-var("FooterBrandBS")} {
    @include footer-100;
  }

  & * {
    @extend .bg-transparent;
    color: $light;

    .#{shared-var("FooterBrandBS")} {
      @extend .bg-secondary;
    }
  }
}
