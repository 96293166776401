.#{shared-var("FilterWidgetBS")} {
  label[for="privileged_filters-with_files"],
  label[for="privileged_filters-with_flags"],
  label[for="privileged_filters-with_stock"] {
    @extend .text-info;
    &::after {
      content: " (adminOnly)";
    }
  }
}
