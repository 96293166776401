.#{shared-var("ProductCategoryItemBS")} {
  height: 100%;

  .col-9,
  .col-md-8 {
    @extend .pr-0;
  }

  .col-3,
  .col-md-4 {
    @extend .pl-0;
  }

  &.card {
    @extend .text-muted;
    @extend .w-100;
  }

  .thumbnail {
    .nav-link {
      @extend .py-0;
      :not(.#{shared-var("DiscountBS")}) {
        @extend .align-items-center;
      }
      // see https://uicookies.com/css-ribbons/ for different ribbon-like types
      .#{shared-var("DiscountBS")} {
        @extend .position-absolute;
        &:not(.rect) {
          @extend .rounded-circle;
          @extend .ml-2;
          padding-top: 1.33rem;
          height: 4rem;
        }
        &.rect {
          @extend .p-1;
          top: 0;
          left: 0;
          height: 2rem;
          border-radius: 4px 0px 6px;
        }

        @extend .font-weight-bold;
        width: 4rem;
      }
    }

    .card-img-top {
      @extend .mx-auto;
      //@extend .h-100;
      @extend .d-block;
      @extend .w-100;

      max-width: fit-content;
      max-width: 100%;
      max-height: fit-content;
      object-fit: contain;
    }
  }

  &-summary {
    @extend .pb-0;
    @extend .h-auto;
    @extend .d-flex;
    @extend .flex-column;
    @extend .justify-content-end;

    .#{shared-var("ProductCategoryItemBS")}-title {
      &.card-title {
        font-size: initial;
      }
      &.card-subtitle {
        font-size: small;
      }

      .search-hint-score,
      .search-hint-order {
        @extend .position-absolute;
        @extend .font-weight-light;
        @extend .p-1;
        @extend .rounded;
        font-size: 0.6em;
        top: 0.5em;
        opacity: 33%;
      }

      .search-hint-score,
      .search-hint-order {
        &:hover {
          opacity: 1;
        }
      }

      .search-hint-score {
        @extend .text-dark;
        right: 1em;
        background-color: greenyellow;
      }

      .search-hint-order {
        @extend .text-light;
        left: 1em;
        background-color: fuchsia;
      }

      .highlight {
        background-color: lighten(yellow, 30%);

        &:hover {
          background-color: yellow;
        }
      }
    }
  }

  &-brand {
    &-title {
      @extend .text-uppercase;
      @extend .font-weight-bolder;
      @extend .py-1;
      font-size: initial;
    }
  }

  &-buttons {
    .#{shared-var("ProductButtonsBS")} {
      @extend .px-0;
      @extend .mx-1;
      button {
        @extend .font-weight-bold;
        @extend .text-uppercase;
      }
    }
  }

  .#{shared-var("ProductRatingBS")}-rating {
    .#{shared-var("RatingWidgetBS")}-stars {
      @extend .pb-0;
      //@extend .text-right;
    }

    text-align: center;
    text-align: unset;
  }

  .item-compare-toggle > div:first-child {
    background-color: $body-bg;
  }
}
