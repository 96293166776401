.#{shared-var("PageBreadcrumbBS")} {
  .breadcrumb {
    background-color: $breadcrumb-bg !important;
    &-item {
      color: $breadcrumb-fg;
      &::before {
        color: $breadcrumb-divider-color;
      }
      &.active {
        color: $breadcrumb-active-color;
      }
      &.is-home {
        display: $breadcrumb-display-home;
      }
    }
  }
}
