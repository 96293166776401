.#{shared-var("BootstrapFormCheckBS")} {
  // variables
  $bootstrap-form-check-margin: 2px;
  $bootstrap-form-check-margins: 2 * $bootstrap-form-check-margin;
  $bootstrap-form-check-innerSize: calc(
    #{$custom-control-indicator-size} - #{$bootstrap-form-check-margins}
  );

  label {
    @extend .cursor-pointer;
  }

  input {
    &[type="checkbox"],
    &[type="radio"] {
      &:focus {
        @include bootstrap-form-check-focus;
      }

      &:disabled {
        @include bootstrap-form-check-borderColor(true);
      }

      @extend .d-none;
    }

    &[type="checkbox"] {
      &:checked {
        & + label::after {
          background-image: none !important;
          margin: $bootstrap-form-check-margin;
          width: $bootstrap-form-check-innerSize;
          height: $bootstrap-form-check-innerSize;
        }

        & + label::before {
          color: $light !important;
          background-color: $light !important;
          @extend .m-0;
        }

        &:not(:disabled) {
          @include bootstrap-form-check-bgColor("after");
        }

        &:disabled {
          @include bootstrap-form-check-bgColor("after", true);
        }
      }

      & + label.rounded-0::before {
        border-radius: 0 !important;
      }
    }

    &[type="radio"] {
      &:checked {
        &:not(:disabled) {
          @include bootstrap-form-check-bgColor("before");
        }
        &:disabled {
          @include bootstrap-form-check-bgColor("before", true);
        }
      }
    }

    @include bootstrap-form-check-borderColor;
  }
}
