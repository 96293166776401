// an animated progress of dots
.dotdotdot:after {
  font-weight: 300;
  content: "...";
  display: inline-block;
  width: 20px;
  text-align: left;
  animation: dotdotdot 1.5s linear infinite;
}

@keyframes dotdotdot {
  0% {
    content: "...";
  }
  25% {
    content: "";
  }
  50% {
    content: ".";
  }
  75% {
    content: "..";
  }
}

// highlight buggy components
.undefined {
  background-color: $danger;
}

// global variable
$navbar-height: 59px;
$header-height: 35px;

// override the Bootstrap built-in class globally here

@import "../default";

// define a custom color template by HTML color name
$custom-color-map: (
  "purple": (
    base: Purple,
    contrast: white
  ),
  "orange-red": (
    base: OrangeRed,
    contrast: white
  ),
  magenta: (
    base: Magenta,
    contrast: white
  )
);

// generate the btn-*, text-* color variants for our custom color template
@each $color, $colorMap in $custom-color-map {
  $baseColor: map-get($colorMap, "base");
  $contrastColor: map-get($colorMap, "contrast");
  @include text-emphasis-variant(".text-#{$color}", $baseColor, true);
  @include override-button(#{$color}, $baseColor, $contrastColor);
}
