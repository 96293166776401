.#{shared-var("PageTemplateBS") }-#{shared-var("CustomMadePageBS") } {
  .#{shared-var("CustomMadePageBS") } {
    &-lead {
      .#{shared-var("TitledParagraphBS") } {
        &-title {
          @extend .py-3;
        }
      }
    }

    &-ordering-steps {
      @extend .px-5;
      @extend .bg-light;

      .#{shared-var("ImageFootnoteCardDeckBS") } {
        &-title {
          @extend .text-left;
          @extend .border-bottom;
          @extend .pb-1;
        }
        &-items {
          .card-item {
            @extend .border-0;

            .top-image {
              @extend .p-3;
              background-color: $gray-200;
              height: 250px;
              img {
                object-fit: contain;
              }
            }
            .card-footer {
              @extend .border-0;
              @extend .bg-light;
              color: inherit;
            }
          }
        }
      }
    }

    &-product-categories {
      .#{shared-var("ImageFootnoteCardDeckBS") } {
        &-items {
          .card-item {
            @extend .border-0;

            .card-header {
              @extend .px-1;

              h3 {
                @extend .border-bottom;
                @extend .pb-1;
              }

              background-color: inherit;
            }
          }
        }
      }
    }

    &-cost-delivery {
      .#{shared-var("TitledParagraphBS") } {
        &-title {
          h2 {
            @extend .border-bottom;
          }
        }
      }

      &.#{shared-var("TableCollapsibleBS") } {
        @extend .bg-light;
      }

      &.footnote {
        @extend .font-italic;
      }
    }

    &-color-preference {
      &-lead {
        h2 {
          @extend .border-bottom;
          @extend .pb-1;
        }
      }

      &-table {
        @extend .bg-light;
      }
    }

    &-links {
      .#{shared-var("TitledParagraphBS") } {
        &-title {
          @extend .py-3;
          h2 {
            @extend .border-bottom;
            @extend .pb-1;
          }
        }
      }

      ul {
        @extend .mx-3;
      }
    }
  }
}
