.#{shared-var("ContactWorktimeBS")} {
  &.row {
    @extend .my-2;
    @extend .my-sm-0;
  }

  p {
    &.h4 {
      text-decoration: $link-hover-decoration;
    }
  }
}
