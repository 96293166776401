.#{shared-var("ProductAvailabilityBS")} {
  &-from {
    &-title,
    &-date {
      @extend .text-danger;
      @extend .text-nowrap;
      font-size: 0.75rem;
    }
  }
}
