.#{shared-var("ControlPanelBS")} {
  max-width: 100% !important;
  width: 90vw !important;

  .container {
    max-width: 100% !important;
  }

  &.maximized {
    .#{shared-var("SiteSettingsToolbarBS")} {
      top: 0;
    }

    .#{shared-var("PageEditorBS")}.container {
      @extend .border-0;
      background-color: unset;
    }

    .preview-wrapper.container {
      @include media-breakpoint-up(xl) {
        max-width: $desktop-width !important;
        width: $desktop-width !important;
      }
      background-color: $white;
      min-height: 50vh;

      &:hover {
        @extend .border;
        @extend .rounded;
        box-shadow: $box-shadow;
      }
    }
  }
}
