.#{shared-var("MenuBarBS")} {
  margin-bottom: 0em;
  width: 100%;
  /*   z-index: $zindex-sticky;
  @extend .position-sticky;
  top: 0;
 */
  nav {
    @extend .p-0;
  }

  .nav-link {
    @extend .px-3;
    @extend .border-right-0;
    @extend .font-weight-bold;

    &:last-child {
      @extend .border-0;
    }
  }
}
