$corner-ribbon-defaults: (
  bg-color: #000000,
  fg-color: #ffffff,
  font-family: inherit,
  font-size: inherit,
  margin-left: auto,
  margin-right: auto,
  margin-top: auto,
  margin-bottom: auto,
  max-width: "auto"
);

// https://www.freecodecamp.org/news/make-a-css-only-ribbon/
@mixin ribbon-corner {
  @content;

  @include init-common-style($cover: true);

  $rotation-angle: 45deg;
  $translation-x: 29.2893218813%; // ie. 1 - cos(45deg)

  box-shadow: 0 0 0 999px var(--bg-color);
  clip-path: inset(0 -100%);

  // default style when no position given (default=top-left)
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;

  margin-top: var(--margin-top);
  margin-right: var(--margin-right);
  margin-bottom: var(--margin-bottom);
  margin-left: var(--margin-left);
  max-width: var(--max-width);

  transform-origin: top right;
  transform: translate(-#{$translation-x}) rotate(-#{$rotation-angle});

  &.corner-top-right {
    //top: 0;
    right: 0;
    //bottom: auto;
    left: auto;

    transform-origin: top left;
    transform: translate(#{$translation-x}) rotate(#{$rotation-angle});
  }

  &.corner-bottom-left {
    top: auto;
    //right: auto;
    bottom: 0;
    //left: 0;

    transform-origin: bottom right;
    transform: translate(-#{$translation-x}) rotate(#{$rotation-angle});
  }

  &.corner-bottom-right {
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;

    transform-origin: bottom left;
    transform: translate(#{$translation-x}) rotate(-#{$rotation-angle});
  }
}
