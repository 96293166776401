.#{shared-var("PageComponentSelectorBS")} {
  .not-available-component {
    @extend .position-relative;

    .not-available-overlay {
      @extend .position-absolute;
      @extend .w-100;
      @extend .h-100;
      @extend .bg-light;
      z-index: 1;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
