.#{shared-var("DevToolbarBS")} {
  @extend .position-fixed;
  @extend .border;
  @extend .rounded;
  @extend .p-2;
  @extend .m-0;
  @extend .text-center;
  @extend .cursor-pointer;

  z-index: 2147483647; // make sure we stay float

  background-color: #eeeeee;
  background-color: rgba(0, 0, 0, 0.05);

  $width: 4rem;
  width: $width;
  height: fit-content;
  top: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);

  margin-left: -#{$width/2 - 0.5rem} !important;
  transition: 0.5s ease-in-out;

  &:hover {
    margin-left: #{$width/2} !important;
  }

  &-toggle,
  &-wireframe,
  &-version,
  &-deptree,
  &-dataset,
  &-ribbon {
    @extend .m-1;
  }

  &-toggle:hover {
    @extend .text-warning;
  }
}

.__DEV__ * {
  cursor: crosshair !important;
  outline: 1px dashed $gray-300;

  &:hover {
    outline-color: $info;
    outline-style: solid;
    box-shadow: 0 0 10px $warning;
  }
}
