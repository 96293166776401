/**
* Put all the CSS declarations, hacks and things we are not proud of in a shame file.
* This file would be imported after any other file, at the very end of the stylesheet.
*/

/**
 * until https://github.com/facebook/create-react-app/issues/11771
 * gets fixed, let's hide the problem and not address it
 */
body
  > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
  display: none;
}
