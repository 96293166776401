.#{shared-var("MarqueeWidgetBS")} {
  @extend .position-relative;
  @extend .mx-0;
  @extend .py-2;
  @extend .justify-content-md-center;
  @extend .border-bottom;

  &-item {
    img,
    svg {
      max-height: 29px;
    }

    @extend .my-auto;
    @extend .text-center;
    @extend .px-1;
    @extend .d-none;
    @extend .d-md-flex;
    & > * {
      @extend .w-100;
    }
  }

  &-carousel {
    @extend .p-2;

    &.slide {
      @extend .w-100;
    }

    .carousel-inner {
      @extend .text-center;
      min-height: 32px;
    }
  }
}
