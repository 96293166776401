.#{shared-var("SiteSettingsToolbarBS")} {
  @extend .sticky-top;
  z-index: $zindex-dropdown - 1 !important; // menu has higher priority

  @include media-breakpoint-down(sm) {
    top: $navbar-height - $header-height;
  }

  @include media-breakpoint-up(md) {
    top: $navbar-height ;
  }
}
