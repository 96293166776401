.#{shared-var("PageTemplateBS") }-#{shared-var("ImprintPageBS") } {
  p {
    @extend .mb-4;
  }

  h1,
  h2,
  h3 {
    @extend .font-weight-bold;
    font-size: inherit;
  }

  .#{shared-var("TitledParagraphBS")} {
    @extend .mb-4;

    &-title {
      @extend .mt-3;
      @extend .h5;
      @extend .font-weight-bold;
    }

    .#{shared-var("ImprintPageBS") }-lead {
      @extend .h2;
    }
  }
}
