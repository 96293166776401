.#{shared-var("PlaceholderBS")} {
  @mixin glow() {
    animation: 2000ms ease-in-out 0ms infinite normal none running
      placeholder-glow;
  }

  @mixin wave() {
    animation: placeholder-wave 2s linear infinite;

    mask-image: linear-gradient(
      130deg,
      #000 55%,
      rgba(0, 0, 0, 0.8) 75%,
      #000 95%
    );

    mask-size: 200% 100%;
  }

  @keyframes placeholder-glow {
    50% {
      opacity: 0.2;
    }
  }

  @keyframes placeholder-wave {
    100% {
      mask-position: -200% 0;
    }
  }

  @extend .d-inline-block;
  @extend .w-100;
  @extend .bg-secondary;
  @extend .my-2;
  min-height: 1rem;
  cursor: wait;
  opacity: 0.5;

  &.glow {
    @include glow();
  }

  &.wave {
    @include wave();
  }

  &.w-75 {
    width: 75% !important;
  }

  &.w-50 {
    width: 50% !important;
  }

  &.w-25 {
    width: 25% !important;
  }
}

svg.#{shared-var("PlaceholderBS")} {
  @extend .text-secondary;
  background-color: inherit !important;
}
