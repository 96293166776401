// these styles can be overriden later sitewise

// the max-width the body content is constrained on large desktops
$desktop-width: 1240px;

body {
  @include media-breakpoint-up(xl) {
    background-color: $light;
  }

  .#{shared-var("PageBodyBS")} {
    background-color: $white;
  }

  a {
    color: unset;
    & > img,
    & > div {
      @include hover-opacity;
    }
  }

  ::placeholder {
    color: $gray-500 !important;
  }
}
