.#{shared-var("BackgroundButtonCardDeckBS") } {
  &-overlay {
    z-index: 2;
    background: rgba(0, 0, 0, 0.3);
    button {
      bottom: 1rem;
      @extend .position-absolute;
    }
  }

  &-image {
    @extend .w-100;
    @extend .h-100;

    min-height: 100%;
    min-height: fit-content;
  }

  .card-title {
    h3 {
      font-size: inherit;
      @extend .font-weight-bold;
    }
  }
}
