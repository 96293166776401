.#{shared-var("NewspaperJumpBS")} {
  &-col {
    column-count: 2;

    @include media-breakpoint-down(sm) {
      column-count: 1;
    }

    &-item {
      @extend .text-center;
      @extend .p-2;

      &-image {
        @include circled-image("bg-light", 4, 0.5);
        img,
        svg {
          height: 3rem !important;
        }
      }
    }
  }
}
