$a3: #00a751; /*A+++*/
$a2: #4fba47; /*A++*/
$a1: #c2d82e; /*A+*/
$a: #fef300; /*A*/
$b: #fcbb0b; /*B*/
$c: #f3701a; /*C*/
$d: #ee141f; /*D*/

$energy-colors: (
  "energy-a3": $a3,
  "energy-a2": $a2,
  "energy-a1": $a1,
  "energy-a": $a,
  "energy-b": $b,
  "energy-c": $c,
  "energy-d": $d,
  "red": $d,
  "orange": $c,
  "yellow": $a,
  "green": $a3
);

$brands-colors: (
  "facebook": #4267b2,
  "google": #1a73e8,
  "twitter": #1da1f2
);
