.#{shared-var("ProductCategoryBS")} {
  &-header {
    @extend .px-0;
    @extend .px-sm-3;

    .row {
      @extend .mx-0;
    }
  }

  &.fetching {
    //filter: blur(1px);
    opacity: 0.4;
  }
}
