.#{shared-var("SiteSettingsMenuBS")} {
  .text-fatal {
    @extend .bg-danger;
    @extend .text-light;
  }
  &-folder {
    &-items.folder {
      max-height: 15rem;
      overflow-y: auto;
      &:hover {
        @extend .border;
        @extend .rounded;
      }
    }
  }
}
