.#{shared-var("CustomFormRangeBS")} {
  $track-color: #e4e3e1;//$gray-300;
  $progress-color: darken($track-color, 40);
  $thumb-color: darken($track-color, 20);
  $stripped-progress: false; // when true apply stripes to progress bar
  $thumb-height: 1.5; //rem
  $thumb-width: 1; //rem
  $track-height: 0.5; //rem
  $top-offset: 1; //rem
  min-height: #{$thumb-height + $top-offset + 1}rem;

  &-wrapper {
    @extend .border-bottom;
    @extend .w-100;
    @extend .pb-2;
    @extend .mb-2;
    @extend .mx-0;
    @extend .px-4;

    $border-color: $gray-200;
    border-image: linear-gradient(
        to right,
        $border-color,
        $gray-500,
        $border-color
      )
      1;
  }

  position: relative !important;
  //@extend .position-relative;
  @extend .w-100;
  @extend .mb-2;
  @extend .mx-0;

  &.disabled {
    @extend .text-muted;
    cursor: not-allowed !important;
  }

  @mixin thumb-shape() {
    width: #{$thumb-width}rem;
    border-radius: 10%;

    &.circle {
      width: #{$thumb-height}rem;
      border-radius: 50%;
    }

    &.square {
      width: #{$thumb-height}rem;
      border-radius: 10%;
    }

    &.stripped {
      @extend .text-center;

      &::before,
      &::after {
        content: "|";
        color: $track-color !important;
      }
    }
  }

  .track {
    @extend .cursor-pointer;
    top: 0;
    left: 0;
    height: #{$track-height}rem;
    background-color: $track-color;
  }

  .min-progress,
  .max-progress {
    @extend .position-absolute;
    z-index: 2;
    background-color: $progress-color;

    /* stripes */
    &.stripped {
      background: repeating-linear-gradient(
        45deg,
        $progress-color,
        $progress-color 5px,
        lighten($progress-color, 20) 5px,
        lighten($progress-color, 20) 10px
      );
    }

    left: 0;
    top: 0;
    width: 0;
    height: #{$track-height}rem;
  }

  .thumb {
    @extend .position-absolute;
    @extend .cursor-pointer;
    z-index: 3;
    background-color: $thumb-color !important;

    /* adjust the width/height/radius for different shapes*/
    top: #{- ($thumb-height - $track-height) / 2}rem;
    height: #{$thumb-height}rem;

    @include thumb-shape();

    &:focus {
      box-shadow: 0 0 1rem darken($thumb-color, 25);
      background-color: darken($thumb-color, 30) !important;
      cursor: grabbing !important;
    }

    &:hover {
      background-color: darken($thumb-color, 20) !important;
      cursor: grab;
    }
  }

  &.disabled .thumb {
    cursor: not-allowed !important;

    &:focus {
      box-shadow: none;
    }
  }

  .wrapper,
  .value {
    @extend .position-absolute;
    top: #{$top-offset}rem;
  }

  .wrapper {
    @extend .w-100;
    min-height: 3rem;
  }

  .min-value {
    @extend .text-left;
    left: 0;
  }

  .max-value {
    @extend .text-right;
    @extend .float-right;
    right: 0;
  }

  .thumb-tooltip {
    @extend .text-black-50;
    @extend .position-absolute;
    top: #{- 1.5 * ($thumb-height - $track-height) / 2}rem;
    display: none; // do not use .d-none class !!!
  }

  .histogram {
    @extend .w-100;
    @extend .position-absolute;

    height: 1.5rem;
    top: -0.5rem;

    .bin {
      &.on {
        background-color: darken(#e4e3e1,15);//darken($gray-200, 10);
        &:hover {
          @extend .bg-dark;
        }
      }
      &.off {
        background-color: lighten(#e4e3e1,2);//$gray-200;
      }

      @extend .d-inline-block;
      @extend .cursor-pointer;

      min-height: 0.25rem;
    }
  }
}
