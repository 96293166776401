.#{shared-var("PageTemplateBS") }-#{shared-var("BuyingGuidePageBS") } {
  p {
    @extend .mb-4;
  }

  h1,
  h2,
  h3 {
    @extend .font-weight-bold;
    font-size: inherit;
  }

  h4 {
    @extend .m-0;
    @extend .position-absolute;
    top: 1.5rem;
  }

  .#{shared-var("TitledParagraphBS")},
  .#{shared-var("MediaBS")} {
    @extend .mb-4;

    &-title {
      @extend .mt-3;
      @extend .h3;
      @extend .font-weight-bold;
    }
  }

  .#{shared-var("NewspaperJumpBS")} {
    .#{shared-var("TitledParagraphBS")} {
      &-title {
        @extend .h5;
      }
    }
  }
}
