.#{shared-var("ImagePreviewBS")} {
  .carousel-inner {
    @extend .h-100;
    @extend .position-absolute;

    picture {
      @extend .h-100;
      img {
        @extend .h-100;
        object-fit: contain !important;
      }
    }

    video {
      @extend .h-100;
    }
  }
}
