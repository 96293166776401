.#{shared-var("UIBlockBS")} {
  @extend .fade;

  $z-index: $zindex-sticky + 1;
  z-index: $z-index !important;

  .spinner-border,
  .overlay-text {
    @extend .position-fixed;
    z-index: $z-index + 1 !important;
  }

  .spinner-border {
    left: calc(50% - #{$spinner-width} / 2) !important;
    top: calc(50% - #{$spinner-height} / 2) !important;
  }

  .overlay-text {
    @extend .bg-primary;
    @extend .text-center;
    @extend .text-light;
    @extend .py-4;
    @extend .rounded;
    @extend .w-50;

    top: calc(50% - 2rem);
    left: 25%;

    .inner-text {
      @extend .dotdotdot;
    }
  }

  // elements with this class won't be blocked
  &-unblock {
    z-index: $z-index + 1 !important;
  }
}
