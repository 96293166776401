.#{shared-var("GDPRCookieControllerBS")} {
  @extend .my-2;
  @extend .my-sm-5;
  @extend .p-3;
  @extend .overflow-auto;

  height: fit-content;
  max-width: $desktop-width !important;
  max-height: 100vh !important;

  button.close {
    @extend .position-absolute;
    top: 1rem !important;
    right: 1rem !important;
  }

  a.nav-link {
    &.active {
      @extend .font-weight-bold;
    }
    &:not(.active) {
      @extend .text-muted;
    }
  }

  &.consent-only {
    .description {
      @extend .text-center;
    }
  }

  .row {
    @extend .mx-auto;

    &.collapsed-cookie-selection {
      @extend .pt-2;
      @extend .border-top;
    }
  }

  .title h2 {
    @extend .h4;
  }

  .description {
    @extend .my-2;
  }

  .action-buttons {
    @extend .text-center;
    @extend .mb-2;
    @extend .h-100;

    button,
    a {
      @extend .m-2;
    }
  }

  .tabs {
    @extend .pr-0;
  }

  .tabs-content {
    @extend .pl-0;

    @extend .border-top;
    @extend .border-right;
    @extend .border-bottom;
    @extend .rounded-right;

    @include media-breakpoint-down(sm) {
      border: $border-width solid $border-color !important;
      border-radius: $border-radius !important;
    }

    @extend .overflow-auto;

    max-height: 50vh !important;

    .tab-content {
      @extend .p-3;

      .table-light {
        td,
        th,
        thead > th {
          border-color: $table-border-color;
        }
      }

      tr {
        &.own-cookie,
        &.incognito {
          @extend .text-dark;
        }
        &.own-cookie:not(.incognito) {
          background-color: #fff8dc !important;
        }
        &.incognito {
          @extend .bg-warning;
        }
        &.border-top-thick {
          @extend .border-top;
          border-top-style: double !important;
          border-top-width: 2 * $border-width !important;
        }
      }
    }
  }

  .purpose {
    @extend .m-2;

    .items {
      @extend .text-center;

      form {
        @extend .d-inline-block;
        @extend .w-auto;

        @extend .border;
        @extend .rounded;

        @extend .px-2;

        .form-check {
          @extend .align-middle;
          @extend .py-2;
        }
      }
    }
  }

  .theme-toggle-container {
    @include media-breakpoint-up(sm) {
      border-right: $border-width solid $border-color !important;
    }
  }

  .nav.flex-column {
    .nav-item > a.nav-link {
      @include media-breakpoint-up(sm) {
        &:not(.active) {
          border-right: $border-width solid $border-color !important;
        }

        &.active {
          border: $border-width solid $border-color !important;
          border-right: 0 !important;
          border-top-left-radius: $border-radius !important;
          border-bottom-left-radius: $border-radius !important;
        }
      }
      @include media-breakpoint-down(sm) {
        &.active {
          border-left: $border-width solid $border-color !important;
        }
      }
    }
  }

  .grip-handle {
    @extend .rounded-lg;
    @extend .rounded;
    @extend .position-absolute;
    @extend .text-center;

    cursor: grab;
  }
}

.cookie-ribbon {
  @extend .fixed-bottom;
  @extend .cursor-pointer;

  fill-opacity: 0.3;
  &:hover {
    fill-opacity: 1;
  }

  g.cookies {
    fill-opacity: 1;
  }
}

##{shared-var("CookieRootBS")} {
  &.dark-theme {
    &,
    .grip-handle {
      @extend .bg-dark;
      @extend .text-light;
    }

    .grip-handle:hover {
      color: rgba($light, 0.5) !important;
    }

    a.nav-link {
      &.active {
        @extend .text-light;
      }
    }
  }
  &.light-theme {
    &,
    .grip-handle {
      @extend .bg-light;
      @extend .text-dark;
    }

    .grip-handle:hover {
      color: rgba($dark, 0.5) !important;
    }

    a.nav-link {
      &.active {
        @extend .text-dark;
      }
    }
  }

  &.fixed-top-pushdown {
    @extend .position-relative;

    .#{shared-var("GDPRCookieControllerBS")} {
      @extend .mt-0;
    }
  }

  &.fixed-top {
    @extend .fixed-top;
  }

  &.fixed-bottom,
  &.fixed-top,
  &.fixed-top-pushdown {
    .grip-handle {
      left: 0;
      top: -0.5em;
      width: 3em;
      margin-left: 50%;
      margin-right: 50%;
      box-shadow: 0px -0.5rem 1rem 0 rgba(0, 0, 0, 0.15) !important;
    }
  }

  &.fixed-bottom {
    box-shadow: 0px -0.5rem 1rem 0 rgba(0, 0, 0, 0.15) !important;
  }

  &.fixed-top,
  &.fixed-top-pushdown {
    z-index: $zindex-fixed !important;
    box-shadow: 0px 0.5rem 1rem 0 rgba(0, 0, 0, 0.15) !important;

    .grip-handle {
      top: unset;
      bottom: -0.5em;
      box-shadow: 0px 0.5rem 1rem 0 rgba(0, 0, 0, 0.15) !important;
    }
  }

  &.fixed-right {
    @extend .fixed-top;
    &.show {
      @extend .h-100;
    }

    width: 50%;
    left: 50%;
    box-shadow: -0.5rem 0 1rem 0 rgba(0, 0, 0, 0.15) !important;

    .#{shared-var("GDPRCookieControllerBS")} {
      @extend .my-1;
      height: 100vh;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      left: 10px;
    }

    .grip-handle {
      left: -0.5em;
      height: 3em;
      width: 1em;
      margin: 0;
      top: 50%;
      transform: translateY(-50%);
      padding-top: 0.75em;

      box-shadow: -0.5rem 0 1rem 0 rgba(0, 0, 0, 0.15) !important;
    }
  }

  z-index: ($zindex-fixed + 1) !important;
}
