.#{shared-var("PageComponentEditorBS")} {
  @extend .rounded;
  @extend .bg-light;
  @extend .py-3;
  cursor: auto;

  &::before {
    transform: translateY(-1rem);
    left: 6.5rem;
  }

  &:not(.preview-mode) {
    &:hover,
    &.selected {
      background-color: transparentize($warning, 0.9) !important;
      border-color: transparentize($info, 0.5);
      box-shadow: 0 0 4px 4px #ddd;
    }

    &.selected {
      border-width: 1px;
      border-style: groove;
    }

    &.selected:hover {
      background-color: transparentize($warning, 0.8) !important;
    }

    &:hover {
      background-color: darken($light, 5%) !important;
      border-color: darken($border-color, 10%);
      box-shadow: 0 0 4px 4px #ddd;
    }
  }

  &.preview-mode {
    .btn-toolbar {
      @extend .d-none;
    }
  }

  .component-wrapper {
    min-height: 1.5em !important;

    .editor-wrapper,
    .preview-wrapper {
      @extend .p-1;
    }

    .preview-container {
      .options-container {
        @extend .d-none;
      }
    }

    // .editor-wrapper {
    //   .options-container {
    //     @extend .mb-2;
    //     @extend .p-2;
    //     @extend .border;
    //     @extend .border-info;
    //     @extend .rounded;
    //     background-color: #f5f5dc !important;
    //   }
    // }
  }

  border: 1px dotted $border-color;

  .remove-component {
    @extend .cursor-pointer;
    @extend .position-absolute;
    @extend .text-light;
    @extend .rounded-circle;
    @extend .p-1;

    left: 4.75em;
    margin-top: -0.9em !important;
    background-color: $gray-200;

    &:hover {
      @extend .bg-danger;
    }
  }

  &.invalid,
  &.valid {
    @extend .border;
    border-width: medium !important;
  }
  &.invalid {
    @extend .border-danger;
  }

  &.valid {
    @extend .border-success;
  }
}
