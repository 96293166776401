.#{shared-var("PageTemplateBS") }-#{shared-var("BuyInfoPageBS") } {
  p {
    @extend .mb-4;
  }

  h1 {
    @extend .font-weight-bold;
  }

  h2 {
    @extend .m-0;
    @extend .position-absolute;
    @extend .h4;
    top: 1.5rem;
  }

  .#{shared-var("TitledParagraphBS")} {
    @extend .mb-4;

    h3 {
      @extend .font-weight-bold;
      font-size: inherit;
    }
  }
}
