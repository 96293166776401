.#{shared-var("ProductPriceBS")} {
  white-space: nowrap;

  & > .container {
    @extend .px-0;
  }

  .col {
    @extend .px-0;
  }

  &-actual,
  &-old {
    @extend .align-self-center;
    @extend .px-0;
  }

  &-actual {
    @extend .font-weight-bold;
    color: #5d1b2d;
    font-size: $h2-font-size;
  }

  &-old {
    @extend .text-secondary;
  }

  .row.d-flex {
    .#{shared-var("ProductPriceBS")} {
      &-actual {
        @extend .w-100;
      }
    }
  }
}
