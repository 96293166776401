.#{shared-var("ScrollToTopBS")} {
  $width: 3.5rem;
  @extend .fixed-bottom;
  @extend .cursor-pointer;
  @extend .rounded-circle;
  @extend .text-center;
  @extend .text-light;

  display: none;
  background-color: #cccccc;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 0.75rem 0;
  width: $width;
  height: $width;
  bottom: 10px;
  left: calc(50% - #{$width} / 2);
  opacity: 0;
  z-index: #{$zindex-fixed + 1};

  &:hover {
    color: unset !important;
  }
}
