.#{shared-var("ProductSliderBS")} {
  @extend .px-0;
  @extend .px-sm-3;

  &-title {
    @extend .text-center;
    @extend .font-weight-bold;
  }

  &-slider {
    height: auto !important;

    .carousel-inner {
      @extend .px-2;
    }

    &-group-item {
      @extend .mx-auto;
      @extend .px-2;
    }

    // .carousel-control-prev,
    // .carousel-control-next {
    //   div {
    //     @extend .rounded-circle;
    //     width: 4rem;
    //     height: 4rem;
    //     padding: 1rem;
    //     background-color: rgba(0, 0, 0, 0.2);
    //   }

    //   background-color: transparent !important;
    // }
  }
}
