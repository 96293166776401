.carousel {
  $bgColor: rgba(0, 0, 0, 0.2);

  &-control-next,
  &-control-prev {
    width: auto;

    &:hover {
      background-color: $bgColor;
      svg path {
        stroke: rgba(0, 0, 0, 0.4);
      }
    }
  }

  @include media-breakpoint-down(lg) {
    @include carousel-hover($bgColor);
  }

  &:hover {
    @include carousel-hover($bgColor);
  }
}

.popover {
  box-shadow: $box-shadow;
}

// create button variants for brands-colors
@each $color, $value in $brands-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}
