.#{shared-var("AdminBezierChartBS")} {
  .turning-points {
    .point,
    .halo {
      stroke: $danger;
      fill: $danger;
      cursor: pointer;
    }
    .halo {
      stroke-opacity: 0.3;
      fill-opacity: 0.25;
    }
  }

  .scroll-pan {
    cursor: pointer;
    .left,
    .right {
      .pan {
        fill-opacity: 0.07;
      }
      .arrow {
        fill-opacity: 0.1;
        &:hover {
          fill-opacity: 0.3;
        }
      }
    }
  }
}
