.#{shared-var("ProductFilterCheckboxInputBS")} {
  @extend .mb-1;

  min-width: 2rem;

  &-title {
    @extend .w-100;
    @extend .text-muted;

    &[data-selected]:not([data-selected="0"]) {
      @extend .text-dark;
    }
  }

  &[data-collapsed="true"] {
    label.#{shared-var("ProductFilterCheckboxInputBS")}-title {
      &[data-selected]:not([data-selected="0"]) {
        &::before {
          content: "(" attr(data-selected) ") ";
        }
      }
    }
  }

  .#{shared-var("CustomFormCheckBS")} {
    @extend .mx-0;
    @extend .px-1;
    label {
      @extend .rounded-0;
      @extend .cursor-pointer;
    }
  }

  &-wrapper {
    @extend .border-bottom;
    @extend .w-100;
    @extend .pb-2;
    @extend .mb-2;

    $border-color: $gray-200;
    border-image: linear-gradient(
        to right,
        $border-color,
        $gray-500,
        $border-color
      )
      1;
  }

  &-drilldown {
    @extend .text-center;
    @extend .cursor-pointer;
    @extend .text-secondary;

    &:hover {
      color: inherit !important;
    }

    span {
      @extend .ml-2;
      @extend .font-weight-bold;
      text-decoration: underline;
    }
  }
}
