.#{shared-var("SEOMetricsModalBS")} {
  .text-fatal {
    @extend .bg-danger;
    @extend .text-light;
  }
}

.seo-fatal,
.seo-danger,
.seo-warning {
  border-width: 0.5em !important;
  border-style: dashed !important;

  /*  &::after {
    @extend .position-absolute;
    @extend .mx-1;
    color: $gray-400;
    content: "id : " attr(data-id) " " attr(data-seo-desc);
  }*/
}

.seo-fatal,
.seo-danger {
  @extend .border-danger;
}

.seo-fatal {
  //border-style: solid !important;
}

.seo-warning {
  @extend .border-warning;
}
