.#{shared-var("FooterBrandBS")} {
  &-inner {
    div:not(.row) {
      @extend .my-auto;
      @extend .font-weight-bold;
      @extend .justify-content-center;
    }
  }
  $footer-brand-padding: 0.5rem;

  @include padding($footer-brand-padding);

  a {
    @include padding($footer-brand-padding);
    .nav-link {
      @extend .p-0;
    }
  }

  &-payment-methods {
    @extend .d-none;

    @extend .d-lg-block;

    a {
      @extend .px-2;
    }
  }
}
