.#{shared-var("DropdownGroupMenuItemBS")} {
  //@extend .font-weight-bold;
  //text-decoration: $link-hover-decoration;

  &:active {
    background-color: $navbar-light-active-color !important;
    color: $navbar-dark-active-color !important;
  }

  &.child {
    @extend .font-weight-normal;
    @extend .text-decoration-none;
    @extend .text-muted;

    @for $i from 1 through 2 {
      &.level-#{$i} {
        &:before {
          margin-left: #{$i}rem;
          content: "- ";
        }
      }
    }
  }
}
