.#{shared-var("RatingWidgetBS")} {
  &-title,
  &-stars {
    @extend .p-2;
  }
  &-title {
    @extend .font-weight-bold;
  }

  &-stars {
    &.empty {
      min-height: 2rem;
    }
  }

  text-align: center;

  @include media-breakpoint-up(sm) {
    text-align: right;
  }
}
