// the page body content enclosed between the page-header and page-footer
.#{shared-var("PageBodyBS")} {
  @extend .py-3;
  min-height: 66vh;

  &.container {
    @extend .position-relative;
    @extend .shadow-sm;

    @include media-breakpoint-up(xl) {
      max-width: $desktop-width !important;
      width: $desktop-width !important;
    }

    &.maximized {
      @extend .mw-100;
      @extend .w-100;
      @extend .mx-0;
    }
  }
}
