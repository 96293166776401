.#{shared-var("PageTemplateEditorBS")} {
  @extend .h-100;
  @extend .bg-white;
  @extend .rounded;
  @extend .py-3;

  &::before {
    top: 1.2rem;
    left: 4rem;
  }

  // &::after {
  //   @extend .d-flex;
  //   @extend .h-100;
  //   @extend .align-items-center;
  //   @extend .justify-content-center;
  //   color: $gray-400;
  //   content: "Empty layout container. Add one or more layout items.";
  // }

  border: 2px dashed $border-color;

  &:hover {
    background-color: transparentize($primary, 0.9) !important;
    border-color: darken($border-color, 10%);
    box-shadow: 0 0 4px 4px #ddd;
  }

  .add-template-item {
    &:not(.disabled) {
      @extend .cursor-pointer;
    }
    @extend .position-absolute;
    @extend .text-light;
    @extend .rounded-circle;
    @extend .p-1;

    background-color: $border-color;

    margin: -1.5em !important;

    &:hover:not(.disabled) {
      @extend .bg-primary;
      box-shadow: 0 0 4px 4px #ddd;
    }
  }

  .options-container {
    @extend .mb-2;
    @extend .p-2;
    @extend .border;
    @extend .border-info;
    @extend .rounded;
    background-color: #f5f5dc !important;
  }
}
