.#{shared-var("ImageCarouselBS")} {
  &-item {
    .#{shared-var("LazyLoaderBS")} {
      @extend .d-block;
    }
    img {
      object-fit: contain !important;
    }
  }
}
