.carousel {
  &-control {
    &-prev,
    &-next {
      div {
        &.rounded {
          @extend .rounded-circle;
        }

        width: var(--icon-size, 4rem);
        height: var(--icon-size, 4rem);
        padding: var(--icon-padding, 1rem);
        background-color: rgba(0, 0, 0, var(--background-opacity, 0.2));
      }

      background-color: transparent !important;

      &:hover {
        background-color: rgba(0, 0, 0, var(--hover-opacity, 0)) !important;
      }
    }
  }
}
