.#{shared-var("ProductSerieItemBS")} {
  &-title {
    @extend .text-center;
    @extend .font-weight-bold;
    @extend .h5;
  }

  &-summary-wrapper {
    @extend .d-flex;
    @extend .align-self-stretch;
  }

  a:hover {
    @extend .text-decoration-none;
  }

  &-text {
    //@extend .text-center;
    @extend .text-justify;

    &.text-truncated {
      $text-truncate-breakpoints: (
        xs: 3,
        sm: 3,
        md: 3,
        lg: 3,
        xl: 3
      ) !default;

      @each $name, $lines in $text-truncate-breakpoints {
        @include media-breakpoint-only($name) {
          @include text-truncate-lines(
            $lines,
            $bg-color: $product-series-summary-bg
          );
        }
      }
    }
  }

  &-subtext {
    @extend .bg-secondary;
  }
}
