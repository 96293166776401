// overide these sitewise in ./custom/NNN.scss
$body-bg: $light !default; // the document body bg color
$page-body-bg: #ffffff !default; // page layout bg color
$menu-bg: $dark !default; // the non-mobile main menu header bg color
$menu-sm-bg: $dark !default; // mobile main menu header bg color
$menu-sm-fg: $light !default; // mobile main menu header fg color
$menu-dropdown-bg: $menu-bg !default; // non-mobile menuitems bg-color
$menu-dropdown-fg: $light !default; // non-mobile menuitems fg-color
$header-separator: #4b4b4b !default; // non-mobile main header menuitems separator color
$footer: $dark !default; // footer bg color
$footer-brand: #464545 !default; // footer-brand bg color
$outlet-btn-color: $danger !default; // the homepage campaign outlet btn bg color
$buygift-btn-color: $dark !default; // the homepage campaign buygift btn bg color
$product-series-summary-bg: $dark !default; //the product series item bg color
$product-series-summary-fg: $white !default; //the product series item fg color
$product-series-button-bg: $secondary !default; //the product series item button bg color
// $product-series-button-fg: $white !default; //the product series item button fg color => DEPRECATED!
$product-subcategory-bg: $secondary !default; // the product subcategory item bg color
$product-subcategory-fg: $white !default; // the product subcategory item fg color
$discount-ribbon-bg: $danger !default; // the product discount ribbon bg color
$discount-ribbon-fg: $white !default; // the product discount ribbon fg color
$card-deck-opaque-overlay: true !default; // the card deck has an 30% opaque overlay

$product-category-header-bg: inherit !default; // product category header bg color
$product-category-active-filters-bg: inherit !default; // product category active filters bg color
$product-category-groups-filters-bg: inherit !default; // product category filter groups bg color
$product-category-rounded: false !default; // apply a rounded border to product category sections

$breadcrumb-bg: $gray-200 !default; // breadcrumb bg color
$breadcrumb-fg: inherit !default; // breadcrumb fg color
$breadcrumb-divider-color: $gray-600 !default; // breadcrumb inactive item fg color
$breadcrumb-active-color: $gray-600 !default; // breadcrumb active item fg color
$breadcrumb-display-home: inherit !default;

// these are the common styles that use the current site-specific variables
html {
  scroll-behavior: auto;

  font-family: "Roboto", sans-serif !important;
  font-style: normal !important;
  font-size: 14px !important;
}

.text-decoration-underline {
  text-decoration-line: underline !important;
}

.text-decoration-line-through {
  text-decoration-line: line-through !important;
}

html a:hover {
  @extend .text-decoration-underline;
  color: unset !important;
}

#responsive-navbar-nav {
  .navbar-nav {
    @extend .w-100;
    & > a {
      @extend .px-0, .text-md-center, .text-sm-left;
    }
    .menu-bar-item-dropdown.dropdown.show ~ .dropdown-menu {
      @extend .d-block;
      @extend .w-100;
    }
  }
}

.dropdown-menu.show {
  @extend .d-table;
}

body {
  .#{shared-var("PageHeaderBS")},
  .#{shared-var("PageBodyBS")},
  .navbar.sticky-top {
    background-color: $page-body-bg;
  }
}

@include bg-variant(".bg-dark", $dark);

@include override-button("success", $success);
@include override-button("secondary", $secondary);
@include override-button("warning", $warning);

@include override-header-menubar(
  $menu-bg,
  $menu-sm-bg,
  $menu-sm-fg,
  $menu-dropdown-bg,
  $menu-dropdown-fg,
  $header-separator
);
@include override-footer($footer);
@include override-footer-brand($footer-brand);

@include campaign-button(0, $outlet-btn-color, $outlet-btn-color);
@include campaign-button(1, $buygift-btn-color, $buygift-btn-color);

@include override-product-series(
  $product-series-summary-bg,
  $product-series-summary-fg,
  $product-series-button-bg,
  // $product-series-button-fg => DEPRECATED!
);

@include override-subcategory($product-subcategory-bg, $product-subcategory-fg);

@include override-discount-ribbon($discount-ribbon-bg, $discount-ribbon-fg);
