.modal-90vw {
  width: 90vw !important;
  height: 90vh !important;
  max-width: none !important;
  max-height: none !important;
}

.#{shared-var("JSONViewerBS")} {
  @extend .d-flex;
  @extend .rounded;

  background-color: darken($light, 5%);

  .gutter {
    @extend .px-2;
    @extend .text-right;
    @extend .text-muted;
    background-color: darken($light, 10%);
  }

  .root-object-block {
    @extend .mx-1;
  }

  div {
    &.object-block > *,
    &.array-block > * {
      @extend .ml-4;
    }
  }

  .separator ~ .array-block-start,
  .separator ~ .object-block-start {
    @extend .ml-0;
  }

  .object-block {
    &-start,
    &-end {
      color: dodgerblue;
    }
  }

  .array-block {
    &-start,
    &-end {
      color: dodgerblue;
    }
  }

  span {
    &.key {
      color: cornflowerblue;
    }

    &.comma {
      @extend .text-muted;
    }

    &.separator {
      @extend .text-muted;
      @extend .mx-1;
    }

    &.scalar {
      color: coral;

      &.number {
        color: darkseagreen;
      }
      &.boolean {
        color: royalblue;
      }
      &.date {
        color: blueviolet;
      }
    }

    &.function {
      color: #5f9ea0;
    }
  }
}
