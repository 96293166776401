.#{shared-var("NotificationBarBS")} {
  $scroll-breakpoints: (
    sm: map-get($grid-breakpoints, "sm"),
    md: map-get($grid-breakpoints, "md"),
    lg: map-get($grid-breakpoints, "md")
  );

  $horizontal-scroll-speeds: (
    sm: 2,
    md: 3,
    lg: 4
  );

  @mixin apply-margin-overflow {
    @extend .mx-3;
    @extend .overflow-hidden;
  }

  @mixin pause-on-hover {
    &:hover {
      animation-play-state: paused !important;
    }
  }

  @mixin apply-vertical-scroll($type) {
    &-item-wrapper.scroll-vertical.scroll-#{$type} &-item-text.scroll-vertical {
      @each $breakpoint in map-keys($scroll-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
          @content;
        }
      }

      @content;

      @include pause-on-hover;
    }
  }

  @mixin apply-horizontal-scroll {
    &-item-text.scroll {
      @each $breakpoint in map-keys($scroll-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
          @include scroll-left(map-get($horizontal-scroll-speeds, $breakpoint));
        }
      }

      @include scroll-left;

      @include pause-on-hover;
    }
  }

  /////////////////////////////////////////////////////////////
  // HORIZONTAL SCROLLING

  &-item-wrapper {
    @include apply-margin-overflow;
  }

  &-wrapper {
    .#{shared-var("NotificationBarBS")}-item-wrapper {
      @include apply-margin-overflow;
    }
  }

  @include apply-horizontal-scroll;

  /////////////////////////////////////////////////////////////
  // VERTICAL SCROLLING

  &-item-wrapper.scroll-vertical {
    @extend .overflow-hidden;
    @extend .position-relative;
    @extend .mx-2;
    height: 1.5rem;
    //border: 1px solid red;
  }

  &-item-text.scroll-vertical {
    @extend .position-absolute;
    @extend .mx-2;
    @extend .text-nowrap;
    @extend .text-truncate;
    @extend .overflow-hidden;
    @extend .mx-2;
    @extend .text-center;
    width: calc(100% - 1rem);
    //border: 1px solid blue;
  }

  @include apply-vertical-scroll("in") {
    @include scroll-top-in;
  }

  @include apply-vertical-scroll("out") {
    @include scroll-top-out;
  }
}
