.#{shared-var("MenuBarToggleBS")} {
  @extend .w-50;

  @extend .d-inline-block;
  @extend .px-md-3;
  @extend .px-1;
  @extend .cursor-pointer;

  .title {
    @extend .ml-2;
    @extend .small;
    @extend .font-weight-bold;
    @extend .text-uppercase;
    vertical-align: middle;
  }
}
