.#{shared-var("CardDeckImageCenteredFootnoteBS")} {
  &-title {
    @extend .my-4;

    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }

  &-items {
    @extend .mb-3;
    @extend .justify-content-between;
  }

  &-item {
    @extend .text-center;
    @extend .p-2;
    box-shadow: 2px 4px 10px 0 rgba(0, 0, 0, 0.25);

    &-image {
      @include circled-image("bg-light", 7, 1);
      img,
      svg {
        height: 5rem !important;
      }
    }

    &-caption {
      @extend .small;
      @extend .font-weight-bold;
      @extend .py-3;
      height: 3rem;
    }
  }
}
