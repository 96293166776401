@include extendClass(
  shared-var("ControlledTabsBS") + "-tabitem",
  ("border-left", "border-right", "border-bottom", "rounded-bottom")
);

.#{shared-var("ControlledTabsBS")} {
  .nav-item {
    &.active {
      @extend .font-weight-bold;
    }
    &:not(.active) {
      @extend .bg-light;
      border: 1px solid #eee;
    }
  }

  &-tabitem {
    @extend .p-3;
    &.disabled.card-header {
      @extend .text-muted;
    }
  }

  &.nav-tabs {
    @extend .mt-3;
  }
}
