.#{shared-var("SearchBannerBS")} {
  &-title {
    @extend .text-center;
  }
}

.#{shared-var("BackgroundButtonCardDeckBS") } {
  color: $light;

  &-title {
    @extend .font-weight-bold;
  }
}
