.#{shared-var("PageTemplateItemEditorBS")} {
  @extend .bg-white;
  @extend .rounded;
  @extend .py-3;
  @extend .my-1;
  // cursor: grab;

  // &:active {
  //   cursor: move;
  // }

  &::after {
    left: 3.75rem;
    transform: translateY(-0.1rem);
  }

  border: 1px dashed $border-color;

  &:not(.preview-mode) {
    &:hover,
    &.selected {
      background-color: transparentize($warning, 0.9) !important;
      border-color: transparentize($info, 0.5);
      box-shadow: 0 0 4px 4px #ddd;
    }

    &.selected {
      border-width: 1px;
      border-style: groove;
    }

    &.selected:hover {
      background-color: transparentize($warning, 0.8) !important;
    }
  }

  .remove-template-item,
  .comment-template-item {
    @extend .float-right;
    @extend .cursor-pointer;
    //@extend .position-absolute;
    @extend .text-light;
    @extend .rounded-circle;
    @extend .p-1;

    right: 0;

    background-color: $gray-200;

    margin-top: -1.25em !important;

    &:hover {
      box-shadow: 0 0 4px 4px #ddd;
    }
  }

  .remove-template-item {
    margin-top: -1em !important;
    margin-right: -1em !important;

    &:hover {
      @extend .bg-danger;
    }
  }

  .comment-template-item {
    margin-top: -1.75em !important;
    margin-left: 50% !important;
    margin-right: 50% !important;
    &:hover {
      @extend .bg-warning;
    }
  }

  .add-component {
    @extend .cursor-pointer;
    @extend .position-relative;
    @extend .text-light;
    @extend .rounded-circle;
    @extend .p-1;

    margin-left: calc(50% - 0.75em);

    background-color: $gray-200;

    &:hover {
      @extend .bg-primary;
      box-shadow: 0 0 4px 4px #ddd;
    }
  }

  &.drag-over {
    @extend .border-secondary;
    border-width: 2px;
  }

  &.dragging {
    opacity: 0.4;
  }
}
