.#{shared-var("PageTemplateBS") }-#{shared-var("AssemblyGuidePageBS") } {
  h1 {
    @extend .font-weight-bold;
  }

  h2 {
    @extend .h4;
  }

  h3 {
    @extend .font-weight-bold;
    font-size: inherit;
  }
}
