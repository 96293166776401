.#{shared-var("MenuBarItemBS")} {
  @extend .text-sm-left;
  @extend .text-md-center;

  &-dropdown {
    & ~ .dropdown-menu {
      margin-top: -1px !important;
    }

    width: max-content;
    margin-bottom: 0;

    .dropdown-toggle {
      @extend .text-wrap;
    }

    .dropdown-toggle.nav-link {
      @extend .px-0;
    }

    .dropdown-menu {
      @extend .bg-dark;

      border-top: 0;

      @include media-breakpoint-up(md) {
        box-shadow: 0 0 10px 0 gray;
      }

      .container {
        @extend .mx-0;
      }

      & .dropdown-item {
        &,
        a {
          @extend .text-light;
        }
        &:hover,
        &.selected {
          @extend .bg-light;
          &,
          a {
            @extend .text-dark;
            opacity: 1 !important;
          }
        }
      }

      @include media-breakpoint-up(md) {
        width: 1200px;
        width: max-content;
      }
      @include media-breakpoint-up(sm) {
        width: 960px;
        width: max-content;
      }
    }
  }
}
