.#{shared-var("OpenAIModalBS")} {
  .del,
  .add {
    @extend .d-inline;
  }

  .del {
    background-color: rgba(250, 175, 114, 0.5);
  }
  .add {
    background-color: rgba(173, 255, 47, 0.5);
  }
}
