.#{shared-var("AdminJSONEditorBS")} {
  .#{shared-var("CollapsibleContainerBS")} {
    div label:not(.custom-control-label)::before {
      content: "--";
      color: #eee;
    }
  }

  .child-object {
    border-left: 1px dashed #eee;
    //    border-top: 1px dashed #eee;
    border-bottom: 1px dashed #eee;

    label {
      padding-left: 0px !important;
    }
    label:not(.custom-control-label)::before {
      content: "--";
      color: #eee;
    }
  }

  .swap {
    @mixin swapColor {
      &.swap-from {
        background-color: lighten($danger, 33%);
      }
      &.swap-to {
        background-color: lighten($success, 33%);
      }
    }

    animation-name: fade-in;
    animation-duration: 1s;
    animation-timing-function: ease-out;

    @include swapColor;

    @keyframes fade-in {
      from {
        @include swapColor;
      }
      to {
        background-color: inherit;
      }
    }
  }

  .html-prettier,
  .css-prettier,
  .openai-optimizer {
    &.badge {
      &.disabled {
        @extend .d-none;
      }

      position: absolute;
      @extend .cursor-pointer;
      @extend .btn-outline-secondary;
      @extend .border;

      background-color: #dddddd90;
      margin-right: 0.5rem;
      z-index: 1;
    }
  }

  .html-enhancement-combo {
    @extend .position-absolute;
    @extend .d-flex;
    @extend .justify-content-end;
    @extend .w-100;
    top: 1rem !important;
    z-index: 1;
  }
}
