.#{shared-var(ProductCategoryComparatorItemBS)} {
  height: 100%;

  .card-img-top {
    @extend .mx-auto;
    //@extend .h-100;
    @extend .d-block;
    @extend .w-100;

    max-width: fit-content;
    max-width: 100%;
    max-height: fit-content;
    object-fit: contain;
  }

  .#{shared-var("ProductRatingBS")}-rating {
    text-align: center;
    text-align: unset;
  }

  .#{shared-var("ProductPriceBS")} {
    &-actual {
      @extend .px-3;
    }
  }
}
