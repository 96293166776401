$around-ribbon-defaults: (
  fold-size: 10px,
  top: 30px,
  bottom: auto,
  bg-color: #000000,
  fg-color: #ffffff,
  rounded: 5px,
  font-family: inherit,
  font-size: inherit,
  // shadow: 1=yes, 0=no
  shadow: 1,
  margin-left: auto,
  margin-right: auto,
  margin-top: auto,
  margin-bottom: auto,
  //
  left: calc(-1 * var(--fold-size)),
  right: calc(-1 * var(--fold-size)),
  //
  max-width: unset,
  max-height: unset,
  min-width: unset,
  min-height: unset
);

@mixin ribbon-around {
  @content;

  // common style
  @include init-common-style($cover: true);

  top: var(--top);
  bottom: var(--bottom);

  // default style when no position given (default=right)
  // left: calc(-1 * var(--fold-size));
  // right: calc(-1 * var(--fold-size));

  left: var(--left);
  right: var(--right);

  padding-top: 0;

  padding-right: var(--fold-size);
  padding-bottom: var(--fold-size);
  padding-left: var(--fold-size);

  margin-top: var(--margin-top);
  margin-right: var(--margin-right);
  margin-bottom: var(--margin-bottom);
  margin-left: var(--margin-left);

  max-width: var(--max-width);
  max-height: var(--max-height);
  min-width: var(--min-width);
  min-height: var(--min-height);

  box-shadow: 0 calc(-1 * var(--shadow) * var(--fold-size)) 0 inset #0005;

  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--fold-size)),
    calc(100% - var(--fold-size)) 100%,
    calc(100% - var(--fold-size)) calc(100% - var(--fold-size)),
    var(--fold-size) calc(100% - var(--fold-size)),
    var(--fold-size) 100%,
    0 calc(100% - var(--fold-size))
  );

  border-top-right-radius: var(--rounded);
  border-top-left-radius: var(--rounded);

  &.tilt-down {
    clip-path: polygon(
      0 var(--fold-size),
      var(--fold-size) 0,
      var(--fold-size) var(--fold-size),
      calc(100% - var(--fold-size)) var(--fold-size),
      calc(100% - var(--fold-size)) 0,
      100% var(--fold-size),
      100% 100%,
      0 100%
    );

    box-shadow: 0 calc(var(--shadow) * var(--fold-size)) 0 inset #0005;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: var(--rounded);
    border-bottom-left-radius: var(--rounded);
    padding-top: var(--fold-size);
    padding-bottom: 0;
  }
}
